import styled from "styled-components";

export const Container = styled.div`
    margin-top: 6rem;

    .special-text {
        color: #06CF99;
    }
`;

