import { AnimatedBubbles, DesktopViewLayout, Footer } from "../../components";
import { cleaningdata } from '../../utils';  
import {  Container  } from "./CleaningStyle";

import { NavDataProps } from "./_types";
import Section from '../../components/Section';

const navbarData: NavDataProps[] = [
    { title:'Cleaning', path: '/services/cleaning', active:true },
    { title:'Contact Us', path:'#', ref:'footerSection'}
 ]




export const Cleaning = () => {
    return (
        <DesktopViewLayout navData={navbarData} btnText="Get Started" buttonPath="/pricing" width="230px">
            <Container>
                <AnimatedBubbles />
                <Section
                    buttonName="use-our-service-btn"
                    imageName="cleaning-tools-image"
                    colOne={cleaningdata.sectionOne.colOne}
                    colTwo={cleaningdata.sectionOne.colTwo}
                />
                <Section
                    background="rgba(17, 131, 171, 0.06)"
                    buttonName="download-now-btn"
                    imageName="galaxy-phone"
                    colOne={cleaningdata.sectionTwo.colOne}
                    colTwo={cleaningdata.sectionTwo.colTwo}
                />
                <div id="serviceSection">
                    <Section
                        header={cleaningdata.sectionThree.headerText}
                        colOne={cleaningdata.sectionThree.colOne}
                    />
                </div>
                <div id="downloadSection">
                    <Section
                        background="rgba(6, 207, 153, 0.31)"                   
                        colOne={cleaningdata.sectionFour.colOne}
                        colTwo={cleaningdata.sectionFour.colTwo}
                    />
                </div>
                <div id="footerSection">
                    <Footer />
                </div>
            </Container>            
        </DesktopViewLayout>
    )
}





