import styled from 'styled-components';

const ButtonWrapper = styled.div`
    
    .btn-overlay {
        background: rgba(0, 0, 0, 0.8);
        margin-top: 9rem;
        position: absolute;
        border-radius: 5px;
        padding: .9rem 3.75rem;
        text-align: center;
        color: #fff;
        font-weight: 600;
        cursor: not-allowed;
        display: none;
        z-index: 1;        
    }
    a {
        margin-top: 9rem;
        display: flex;
        text-decoration: none;
        gap: 1rem;
        border-radius: 5px;
        padding: .5rem 2.5rem;

        .icon {
            margin: auto;
            font-size: 25px;
        }
        .text {
            display: grid;
            .norm {
                font-family: Poppins;
                font-size: 14.5px;
                font-weight: 400;
                line-height: 15px;
                letter-spacing: 0em;
                text-align: left;

            }
            .bold {
                font-family: PoppinsBold;
                font-size: 16px;
                font-weight: 600;
                line-height: 20px;
                letter-spacing: 0em;
                text-align: left;
                text-shadow: none;
            }
        }
    }
        
    &:hover {
        .btn-overlay {
            display: block;
        }
    }
`;


export const DownloadButton = (props: any) => {
    const { background, color, path, icon, normText, boldText} = props;


    return (
        <ButtonWrapper>
            <div className='btn-overlay'>coming soon</div>
            <a href={path}  style={{ background: background, color: color}} >
                <div className='icon'>{icon}</div>
                <div className='text'>
                    <span className='norm'>{normText}</span>
                    <span className="bold">{boldText}</span>
                </div>
            </a>
        </ButtonWrapper>
    )
}