import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";
import { useMediaQuery } from 'react-responsive';

import { Nav, Wrapper } from './TopNavBarStyle';

import { Logo } from '../Logo';
import { Button } from '../Button';
import { MobileNavbar } from '../_MobileNavbar/MobileNavbar';

type LinkItems = {
    navData: any,
    btnText: string,
    width: string,
    buttonPath: string
}

export const TopNavbar = ({navData, btnText, width, buttonPath}: LinkItems) => {
    const [dropdownCollapse, setDropdownCollapse] = useState(false);

    const handleDropdown = () => setDropdownCollapse(!dropdownCollapse);

    

    
    const isMobile = useMediaQuery({
        query: "(max-width: 900px)"
    });

   
    
    
    return(
        
        <>
        { isMobile ? <MobileNavbar /> :
            <Nav>
                <Wrapper>
                    <div className='logo'>
                        <Link to={"/"}>                        
                            <Logo height="22px" />
                        </Link>                    
                    </div>
                    <div>
                        <ul style={{width: width}}>
                            {navData.map((item: any, index: any) => (
                                item.path && item.path === '#' ? 
                                <ScrollLink
                                    to={item.ref} 
                                    key={index}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}
                                    className="ref">{item.title}</ScrollLink> :
                                <Link to={item.path} key={index} onClick={item.dropdown && handleDropdown }>
                                    <li className={item.active ? "active" : ""}>
                                        <div>{item.title}</div>
                                        <div className='collapse-icon'>
                                            { 
                                            item.dropdown && !dropdownCollapse ?
                                            item.iconClosed : 
                                            item.dropdown && dropdownCollapse ?
                                            item.iconOpen : null                                    
                                            }
                                        </div>
                                    </li> 
                                    {item.dropdown ? 
                                        <div className={`services-dropdown ${dropdownCollapse ? 'show' : 'hide'}`}>
                                            { item.dropdown.map((item: any, index: any) => (
                                                <Link to={item.path} key={index}>
                                                    {item.title}
                                                </Link>
                                            ))}
                                        </div>
                                    : null }                                                     
                                </Link>
                            ))}
                        </ul>
                    </div>
                    <div className='btn'>
                    {buttonPath ?                     
                        <Button text={btnText} path={buttonPath} /> :
                        <ScrollLink
                            to={"downloadSection"}
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}>                                
                            <Button text={btnText} path={buttonPath} />
                        </ScrollLink>
                    }
                    </div>
                </Wrapper>
            </Nav>
        }
        </>
    )
}