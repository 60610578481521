import { FaApple, FaGooglePlay } from "react-icons/fa";
import styled from "styled-components";
import { BlurredLines } from "./_Animations/BlurredLines";
import { CarouselItem, ImageCarousel } from "./_Carousel/ImageCarousel";

const Wrapper = styled.body`
    height: 100vh;
    background: linear-gradient(-45deg, #a23982,#1f1013, #1183AB, #a23982);
    background-size: 400% 400%;
    animation: gradient 15s ease infinite;

    @keyframes gradient {
        0% {
            background-position: 0% 50%;
        }
        50% {
            background-position: 100% 50%;
        }
        100% {
            background-position: 0% 50%;
        }
    }
`;

const Container = styled.div`
    position: relative;
    top: 250px;
`;

const LogoText = styled.h1`
    text-align: center;
    margin-top: 0;
    color: #fff;
    text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
`;

const TextSlideContainer = styled.div`
    label {
        word-break: break-all;
        white-space: nowrap;
        text-align: center;
        margin-top: 0px;
        font-size: 15px;
        color: white;

        .yellow {
            color: #FFCA66;
            foont-weight: 600;
        }
        .green {
            color: var(--light-green);
            font-weight: 600;
        }
        .blue {
            color: #ef45bb;
            font-weight: 600;
        }
    }
`;

const ButtonsWrapper = styled.div`
    margin-top: 130px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 1.2rem;

    

    a {
        padding: 5px 30px;
        text-decoration: none;
        display: flex;
        background: var(--dark-green);
        gap: 1rem;
        text-align: left;
        border-radius: 5px;
        color: #fff;
        box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);

        &.google-play {
            padding: 4px 45px;
        }

        .icon {
            margin: 0 auto;
            font-size: 23px;
        }
        .playstore {
            margin-top: 3px;
        }

        p {
            margin: 0;
            padding: 0;
            font-size: 12px;
        }
        .bold {
            font-weight: 400;
        }
    }
`;

export const MobileViewLayout = () => {

    return (
        <Wrapper>
            <BlurredLines />
            <Container>
                <LogoText>Worka</LogoText>
                <TextSlideContainer>
                    <ImageCarousel>           
                        <CarouselItem width="350px"> 
                            <label>We help you <span className="yellow">find Workers</span> around<wbr/> your environment </label>
                        </CarouselItem>
                        <CarouselItem>
                            <label>Enjoy a <span className="green">Better</span> and<span className="green"> Healthier<wbr/> Cleaning</span> Experience </label>
                        </CarouselItem>
                        <CarouselItem>
                            <label>Make <span className="blue">Life Easy</span> With Our<span className="blue"><wbr /> Laundry</span> Service</label>
                        </CarouselItem>
                        <CarouselItem>
                            <label>Live <span className="green">Stress Free</span> at a<span className="green"> Budget<wbr/> Friendly</span> Price</label>
                        </CarouselItem>
                    </ImageCarousel>
                </TextSlideContainer>
            

                <ButtonsWrapper>
                    <a href="#">
                        <div className="icon">
                            <FaApple />
                        </div>
                        <div>
                            <p>Download on the</p>
                            <p>App Store</p>
                        </div>
                    </a>
                    <a href="#" className="google-play">
                        <div className="icon playstore">
                            <FaGooglePlay />
                        </div>
                        <div>
                            <p>Get it on</p>
                            <p>Google Play</p>
                        </div>
                    </a>
                </ButtonsWrapper>
            </Container>            
        </Wrapper>
    )
}