import { Link } from 'react-router-dom';
import styled from 'styled-components';
import Logo_White from '../assets/images/worka_logo.svg';

const Wrapper = styled.div`
    display: flexbox;
    span{        
        font-size: 11px;
        color: var(--dark-green);
        text-decoration: none;
    }
`;

export const Logo = ({height}: any) => {
    return(   <Wrapper>
            <Link to="/"><img src={Logo_White} alt="worka logo" height={height} /> <span>beta</span> </Link>
        </Wrapper>
    )
}