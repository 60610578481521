import { FaApple,  FaGooglePlay } from 'react-icons/fa';
import FoodIcon from "../assets/images/service_section/food.svg";
import LaundryIcon from "../assets/images/service_section/laundry.svg";
import CleaningIcon from "../assets/images/service_section/cleaning.svg";


// cleaning page phone image
import Galaxy from "../assets/images/cleaning/galaxy.svg";
import Iphone from "../assets/images/cleaning/iPhone.svg";
import CleaningImage from "../assets/images/cleaning/cleaningImage.svg";

// laundry page phone image
import IphoneL from "../assets/images/laundry/iPhone-L.svg";
import IphoneL1 from "../assets/images/laundry/iPhone-L1.svg";
import LaundryImage from "../assets/images/laundry/laundryImage.svg";

// food page phone image 
import IphoneF from "../assets/images/food/iPhone-F.svg";
import IphoneF1 from "../assets/images/food/iPhone-F1.svg";
import FoodImage from "../assets/images/food/foodImage.svg";


export const cleaningdata = {  
    sectionOne: {
        colOne: {
            headerText: <>Enjoy A <span className="special-text">Better</span> and <span className="special-text">Healthier</span> Cleaning Experience</>,
            paragraphText: <>With our exceptional collection of professional house cleaners to declutter your living space and give it a complete makeover.<br/> Say goodbye to clutters and grime right now</>,
            btnText: "Use Our Service"
        },
        colTwo: {
            image: CleaningImage,
            alt: "cleaning image"
        }
    },
    sectionTwo: {
        colOne: {
            image: Galaxy,
            alt: "galaxy-mockup image"
        },
        colTwo: {
            headerText: "How It Works",
            paragraphText: [
                {
                    headerText: "Select Plan",
                    bodyText: "Place an order for the type of cleaning service you need."
                },
                {
                    headerText: "We Get To Work",
                    bodyText: "We assign a worka to fix your cleaning needs."
                },
                {
                    headerText: "Sit Back And Relax",
                    bodyText: "Your Worka is on the way, sit back and let yourself be taken care of the Worka way!"
                }
        ],
            btnText: "Download Now"
        }
    },
    sectionThree: {
        headerText: "See Our Other Services",
        colOne: [
            {
                background: "rgba(255, 202, 102, 0.13)",
                color: "#FFA700",
                headerImage: LaundryIcon,
                headerText: "Laundry",
                paragraphText: "Freshly washed and neatly ironed clothing items are just an order away!",
                link: "/services/laundry"
            },
            {
                background: "rgba(6, 207, 153, 0.31)",
                color: "#06CF99",
                headerImage: FoodIcon,
                headerText: "Food",
                paragraphText: "Swipe through our menu list on the Worka app, and get yourself a nice treat delivered to your doorstep",
                link: "/services/food"
            },
        ]
    },
    sectionFour: {
        colOne: {
            image: Iphone,
            alt: "iphone-mockup image"
        },
        colTwo: {
            headerText: "Download Our Mobile App ",
            paragraphText: "To enjoy all our services, download the Worka app on Google Playstore or App Store.",
            btnText: [
                {
                    background: "#000000",
                    color: "#FFFFFF",
                    path: "",
                    icon: <FaApple />,
                    normText: "Download on the",
                    boldText: "App Store"

                },
                {
                    background: "#000000",
                    color: "#FFFFFF",
                    path: "",
                    icon: <FaGooglePlay />,
                    normText: "Get it on",
                    boldText: "Google Play"
                }
            ]
        }
    },
 }




 export const laundrydata = {  
    sectionOne: {
        colOne: {
            headerText: <>Make <span className="special-text">Life Easy</span> with Our Laundry Service </>,
            paragraphText: <>Dread doing laundry on weekends? <br/>With Worka, now you can take in the blissful fragrance of freshly washed clothes, without lifting a finger. <br/>Let us take the stress off you!</>,
            btnText: "Use Our Service"
        },
        colTwo: {
            image: LaundryImage,
            alt: "Laundry image"
        }
    },
    sectionTwo: {
        colOne: {
            image: IphoneL,
            alt: "iPhone-mockup image"
        },
        colTwo: {
            headerText: "How It Works",
            paragraphText: [
                {
                    headerText: "Place An Order",
                    bodyText: "Place a quick order from the comfort of your home."
                },
                {
                    headerText: "We Do Your Laundry",
                    bodyText: "We pick up your dirty laundry."
                },
                {
                    headerText: "We Deliver",
                    bodyText: "We Deliver clean and neatly ironed laundry to your doorstep!"
                }
        ],
            btnText: "Download Now"
        }
    },
    sectionThree: {
        headerText: "See Our Other Services",
        colOne: [
            {
                background: "rgba(17, 131, 171, 0.06)",
                color: "#1183AB",
                headerImage: CleaningIcon,
                headerText: "Cleaning",
                paragraphText: "All your house cleaning needs done and dusted with a single tap on your mobile device.",
                link: "/services/cleaning"
            },
            {
                background: "rgba(6, 207, 153, 0.31)",
                color: "#06CF99",
                headerImage: FoodIcon,
                headerText: "Food",
                paragraphText: "Special food cravings? Or surprise meal deliveries for your guys? We are your one-stop plug.",
                link: "/services/food"
            },
        ]
    },
    sectionFour: {
        colOne: {
            image: IphoneL1,
            alt: "iphone-mockup image"
        },
        colTwo: {
            headerText: "Download Our Mobile App ",
            paragraphText: "To enjoy all our services, download the Worka app on Google Playstore or App Store.",
            btnText: [
                {
                    background: "#000000",
                    color: "#FFFFFF",
                    path: "",
                    icon: <FaApple />,
                    normText: "Download on the",
                    boldText: "App Store"

                },
                {
                    background: "#000000",
                    color: "#FFFFFF",
                    path: "",
                    icon: <FaGooglePlay />,
                    normText: "Get it on",
                    boldText: "Google Play"
                }
            ]
        }
    },
 }



 
 export const foodData = {  
    sectionOne: {
        colOne: {
            headerText: <>Get <span className="special-text">Freshly Cooked</span> Meals delivered to your doorstep  </>,
            paragraphText: <>From treating you to breakfast in bed, special lunch orders, or whetting your appetite with our tantalizing appetizers, we are committed to satisfying all your food cravings.</>,
            btnText: "Use Our Service"
        },
        colTwo: {
            image: FoodImage,
            alt: "Food image"
        }
    },
    sectionTwo: {
        colOne: {
            image: IphoneF,
            alt: "iPhone-mockup image"
        },
        colTwo: {
            headerText: "How It Works",
            paragraphText: [
                {
                    headerText: "Place Your Order",
                    bodyText: "Scan through our menu list, and place your food order."
                },
                {
                    headerText: "We Make Tasty Meals",
                    bodyText: "Scrumptious meals to suit your appetite."
                },
                {
                    headerText: "Home Delivery",
                    bodyText: "Tasty meals delivered to your doorstep in good time!"
                }
        ],
            btnText: "Download Now"
        }
    },
    sectionThree: {
        headerText: "See Our Other Services",
        colOne: [
            {
                background: "rgba(17, 131, 171, 0.06)",
                color: "#1183AB",
                headerImage: CleaningIcon,
                headerText: "Cleaning",
                paragraphText: "Come back to a tidy apartment every single day, and destress easily. ",
                link: "/services/cleaning"
            },
            {
                background: "rgba(255, 202, 102, 0.13)",
                color: "#FFA700",
                headerImage: LaundryIcon,
                headerText: "Laundry",
                paragraphText: "Say goodbye to dirty laundry, and get quality time to do what you love.",
                link: "/services/laundry"
            },
        ]
    },
    sectionFour: {
        colOne: {
            image: IphoneF1,
            alt: "iphone-mockup image"
        },
        colTwo: {
            headerText: "Download Our Mobile App ",
            paragraphText: "To enjoy all our services, download the Worka app on Google Playstore or App Store.",
            btnText: [
                {
                    background: "#000000",
                    color: "#FFFFFF",
                    path: "",
                    icon: <FaApple />,
                    normText: "Download on the",
                    boldText: "App Store"

                },
                {
                    background: "#000000",
                    color: "#FFFFFF",
                    path: "",
                    icon: <FaGooglePlay />,
                    normText: "Get it on",
                    boldText: "Google Play"
                }
            ]
        }
    },
 }
