import styled from 'styled-components';

type ButtonProps = {
    text: string,
    path?: string,
    className?: string,
    disabled?: boolean
}

const Input = styled.a`
    background: var(--dark-green);
    color: var(--white);
    white-space: no-wrap;

    
`
const InputWhite = styled.a`
    background: var(--white);
    color: var(--dark-green);    
    white-space: no-wrap;
`;

export const Button = ({text, path, disabled}: ButtonProps) => {
    function handleClick(){ return }
    return (
        <Input href={path} onClick={handleClick}>{text}</Input>
    )
}
export const ButtonWhite = ({text, className}: ButtonProps) => {
    return (
        <InputWhite className={className}>{text}</InputWhite>
    )
}