import styled from "styled-components";

export const Wrapper = styled.div`
    width: 100%;
    background: #0A1A1F;
    padding-top: 50px;
    padding-bottom: 30px;
    box-sizing: border-box;

    .copyright {
        margin-top: 5rem;
        color: #A4A4A4;
        text-align: center;
        font-size: 14px;
        letter-spacing: 0.03em;
    }

`;

export const Col = styled.div`
    width: 100%;
    margin: 0 auto;
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    color: #fff;
    a {
        text-decoration: none;
        font-size: 16px;
        line-height: 30px;
        letter-spacing: 0.04em;

        color: #F9F9F9;

        &:focus {
            color: #fff;
        }
        &:active {
            color: #fff;
        }
    }
    
    li {
        list-style: none;
        margin-left: -2.5rem;font-size: 16px;
        line-height: 35px;
        letter-spacing: 0.04em;
        color: #F9F9F9;
    }

`;

export const Row = styled.div`
    p {
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;        
        letter-spacing: 0.04em;
    }

    .beneath_logo_text {
        font-size: 17px;
        line-height: 35px;
        letter-spacing: 0.02em;
        margin-top: 1rem;
        color: #F9F9F9;
    }
    @media (min-width: 401px) and (max-width: 1023px) {
        flex: 50%;        
        text-align: center;
    }
    @media (max-width: 400px) {
        flex: 100%;
        text-align: center;
    }
`;

export const Logo = styled.img`
        margin-top: 1.5rem;
        margin-left: -1.5rem;
`;

export const SocialLinks = styled.div`
    display: flex;
    justify-content: flex-start;
    gap: 2rem;
    margin-top: 1rem;

    a {
        font-size: 25px;
    }
    @media (max-width: 400px) {
        justify-content: center;
    }
    @media (min-width: 401px) and (max-width: 1023px) {  
        justify-content: center;
    }

`;