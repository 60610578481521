import { AnimatedBubbles, DesktopViewLayout, Footer } from "../../components";
import { foodData } from '../../utils';  
import {  Container  } from "./FoodStyle";

import { NavDataProps } from "./_types";
import Section from '../../components/Section';

const navbarData: NavDataProps[] = [
    { title:'Catering', path: '/services/food', active:true },
    { title:'Contact Us', path:'#', ref: 'footerSection'}
 ]




export const Food = () => {
    return (
        <DesktopViewLayout navData={navbarData} btnText="Get Started" buttonPath="/pricing" width="230px">
            <Container>
                <AnimatedBubbles />
                <Section
                    buttonName="use-our-service-btn"
                    imageName="cleaning-tools-image"
                    colOne={foodData.sectionOne.colOne}
                    colTwo={foodData.sectionOne.colTwo}
                />
                <Section
                    background="rgba(6, 207, 153, 0.31)"
                    buttonName="download-now-btn"
                    imageName="galaxy-phone"
                    colOne={foodData.sectionTwo.colOne}
                    colTwo={foodData.sectionTwo.colTwo}
                />
                <div id="serviceSection">
                    <Section
                        header={foodData.sectionThree.headerText}
                        colOne={foodData.sectionThree.colOne}
                    />
                </div>
                <div id="downloadSection">
                    <Section                        
                        background="rgba(255, 202, 102, 0.13)"                    
                        colOne={foodData.sectionFour.colOne}
                        colTwo={foodData.sectionFour.colTwo}
                    />
                </div>                
                <div id="footerSection">
                    <Footer />
                </div>
            </Container>            
        </DesktopViewLayout>
    )
}





