import { useEffect, useState } from 'react';
import { FaAngleDown, FaAngleUp} from 'react-icons/fa';
import Modal from 'react-modal';
import { useMediaQuery } from "react-responsive";
import { send } from 'emailjs-com';
import { AiFillCloseCircle } from "react-icons/ai";
import { usePaystackPayment } from 'react-paystack';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Minus from '../../assets/images/minus.svg';
import Plus from '../../assets/images/plus.svg';
import { 
    PricingListWrapper,
    CleaningPlanWrapper, 
    FoodPlanWrapper, 
    LaundryPlanWrapper,  
    ExtraItemsContainer, 
    DailyDeliveryWrapper, 
    TwicePerWeekDelivery, 
    WeeklyDeliveryWrapper, 
    TotalAmountWrapper,
    ModalStyle
    } from "./PricingStyle";
import { CurrencyFormatter, prices, public_key } from '../../utils';

export const PricingList = (props: any) => {
    const { laundry, cleaning, food } = props;
    // Laundry state
    const [laundrybagValue, setLaundrybagValue] = useState(1);
    const [serviceType, setServiceType] = useState("wash-iron");
    const [laundryAmount, setLaundryAmount] = useState(prices.laundry.type.washIron[0].once_a_week);
    const [extrasDropdown, setExtrasDropdown] = useState(false);
    const [selectedLaundryFrequency, setSelectedLaundryFrequency] = useState("once-a-week");
    const [extraLaundryItems, setExtraLaundryItems] = useState({
        towel: 0,
        bedsheet: 0,
        agbada: 0,
        blanket: 0,
        duvet: 0,
        curtain: 0,
        suit: 0
    });

    // Food state
    const [foodQuantity, setFoodQuantity] = useState(1);
    const [selectedFoodFrequency, setSelectedFoodFrequency] = useState("daily");
    const [dailyDelivery, setDailyDelivery] = useState("Monday-Friday");
    const [weeklyDelivery, setWeeklyDelivery] = useState("Mon");
    const [twiceAWeekDelivery, setTwiceAWeekDelivery] = useState({ mon: false, tue: false, wed: false, thu: false, fri: false, sat: false });
    const [foodAmount, setFoodAmount] = useState(prices.food.frequency.daily.mon_fri);

    // Cleaning state
    const [cleaningFrequency, setCleaningFrequency] = useState("once-a-week");
    const [showFrequency, setShowFrequency] = useState(true);
    const [cleaningType, setCleaningType] = useState("regular");
    const [cleaningExtrasDropdown, setCleaningExtrasDropdown] = useState(false);
    const [cleaningExtras, setCleaningExtras] = useState({
        bedroom: 1,
        livingroom: 1,
        kitchen: 1,
        store: 0,
        outdoor: 0,
        cleaning: 0
    });
    const [cleaningAmount, setCleaningAmount] = useState(prices.cleaning.type.regular.once_a_week);
    // Total Price
    const [totalAmount, setTotalAmount] = useState(0);
    // Payment Information Modal    
    const [infoCollectionModal, setInfoCollectionModal] = useState(false);

    useEffect(() => {
        // Calculate Total Price
        if(laundry && !cleaning && !food ){ 
            setTotalAmount(laundryAmount);
         }else if(!laundry && cleaning && !food ){ 
            setTotalAmount(cleaningAmount);
        }else if(!laundry && !cleaning && food ){ 
            setTotalAmount(foodAmount);
        }else if(laundry && cleaning && !food ){                     
            setTotalAmount( laundryAmount + cleaningAmount );
        }else if(laundry && !cleaning && food ){ 
            setTotalAmount( laundryAmount + foodAmount);
        }else if(!laundry && cleaning && food ){ 
            setTotalAmount(cleaningAmount + foodAmount);
        }else {
            setTotalAmount(laundryAmount + cleaningAmount + foodAmount) ; 
        }
    }, [laundry, cleaning, food, laundryAmount, foodAmount, cleaningAmount])

    // Laundry Functions 
    const laundryTypeChange = (e: any) => {
        setServiceType(e.target.value);
        if(e.target.value === "wash-iron"){
            setExtraLaundryItems({towel: 0,bedsheet: 0,agbada: 0,blanket: 0,duvet: 0,curtain: 0, suit: 0 });
            if(selectedLaundryFrequency === "once-a-week"){
                setLaundryAmount(prices.laundry.type.washIron[0].once_a_week * laundrybagValue); 
            }else if(selectedLaundryFrequency === "every-two-weeks"){
                setLaundryAmount(prices.laundry.type.washIron[0].every_two_weeks * laundrybagValue);
            }else{
                setLaundryAmount(prices.laundry.type.washIron[0].once_a_month * laundrybagValue);
            }
        }else{            
            setExtraLaundryItems({towel: 0,bedsheet: 0,agbada: 0,blanket: 0,duvet: 0,curtain: 0, suit: 0 });
            if(selectedLaundryFrequency === "once-a-week"){
                setLaundryAmount(prices.laundry.type.washFold[0].once_a_week * laundrybagValue);
            }else if(selectedLaundryFrequency === "every-two-weeks"){
                setLaundryAmount(prices.laundry.type.washFold[0].every_two_weeks * laundrybagValue);
            }else {
                setLaundryAmount(prices.laundry.type.washFold[0].once_a_month * laundrybagValue);
            } 
        }
    }
    const laundryFrequencyChange = (e: any) => {
        setSelectedLaundryFrequency(e.target.value);
        if(e.target.value === "once-a-week"){            
            setExtraLaundryItems({towel: 0,bedsheet: 0,agbada: 0,blanket: 0,duvet: 0,curtain: 0, suit: 0 });
            if(serviceType === "wash-iron"){
                setLaundryAmount(prices.laundry.type.washIron[0].once_a_week * laundrybagValue);
            }else{
                setLaundryAmount(prices.laundry.type.washFold[0].once_a_week * laundrybagValue);
            }
        }else if(e.target.value === "every-two-weeks"){            
            setExtraLaundryItems({towel: 0,bedsheet: 0,agbada: 0,blanket: 0,duvet: 0,curtain: 0, suit: 0 });
            if(serviceType === "wash-iron"){
                setLaundryAmount(prices.laundry.type.washIron[0].every_two_weeks * laundrybagValue);
            }else{
                setLaundryAmount(prices.laundry.type.washFold[0].every_two_weeks * laundrybagValue);
            }
        }else{            
            setExtraLaundryItems({towel: 0,bedsheet: 0,agbada: 0,blanket: 0,duvet: 0,curtain: 0, suit: 0 });
            if(serviceType === "wash-iron"){
                setLaundryAmount(prices.laundry.type.washIron[0].once_a_month * laundrybagValue);
            }else{
                setLaundryAmount(prices.laundry.type.washFold[0].once_a_month * laundrybagValue);
            }
        }
    }
    const laundryBagIncrement = () => {
        setLaundrybagValue(laundrybagValue+1);

        if(serviceType === "wash-iron"){
            if(selectedLaundryFrequency === "once-a-week"){
                setLaundryAmount(laundryAmount + prices.laundry.type.washIron[0].once_a_week);
            }else if(selectedLaundryFrequency === "every-two-weeks"){
                setLaundryAmount(laundryAmount + prices.laundry.type.washIron[0].every_two_weeks);
            }else{
                setLaundryAmount(laundryAmount + prices.laundry.type.washIron[0].once_a_month);
            }
        }else{
            if(selectedLaundryFrequency === "once-a-week"){
                setLaundryAmount(laundryAmount + prices.laundry.type.washFold[0].once_a_week);
            }else if(selectedLaundryFrequency === "every-two-weeks"){
                setLaundryAmount(laundryAmount + prices.laundry.type.washFold[0].every_two_weeks);
            }else {
                setLaundryAmount(laundryAmount + prices.laundry.type.washFold[0].once_a_month);
            } 
        }
    }    
    const laundryBagDecrement = () => {
        if(laundrybagValue === 1){
            return;
        }else{
            setLaundrybagValue(laundrybagValue-1);

            if(serviceType === "wash-iron"){
                if(selectedLaundryFrequency === "once-a-week"){
                    setLaundryAmount(laundryAmount - prices.laundry.type.washIron[0].once_a_week);
                }else if(selectedLaundryFrequency === "every-two-weeks"){
                    setLaundryAmount(laundryAmount - prices.laundry.type.washIron[0].every_two_weeks);
                }else{
                    setLaundryAmount(laundryAmount - prices.laundry.type.washIron[0].once_a_month);
                }
            }else{
                if(selectedLaundryFrequency === "once-a-week"){
                    setLaundryAmount(laundryAmount - prices.laundry.type.washFold[0].once_a_week);
                }else if(selectedLaundryFrequency === "every-two-weeks"){
                    setLaundryAmount(laundryAmount - prices.laundry.type.washFold[0].every_two_weeks);
                }else {
                    setLaundryAmount(laundryAmount - prices.laundry.type.washFold[0].once_a_month);
                } 
            }
        }
    }
    const laundryExtrasDecrease = (val: any) => {
        if(val === 'towel'){
            if(extraLaundryItems.towel === 0){
                return;
            }else{
                setExtraLaundryItems({...extraLaundryItems, towel: extraLaundryItems.towel-1});
                setLaundryAmount(laundryAmount - prices.laundry.extraServices[0].price);
            }

        }else if(val === 'bedSheet'){
            if(extraLaundryItems.bedsheet === 0){
                return;
            }else{
                setExtraLaundryItems({...extraLaundryItems, bedsheet: extraLaundryItems.bedsheet-1});
                setLaundryAmount(laundryAmount - prices.laundry.extraServices[1].price);
            }
        }else if(val === 'agbada'){
            if(extraLaundryItems.agbada === 0){
                return;
            }else{
                setExtraLaundryItems({...extraLaundryItems, agbada: extraLaundryItems.agbada-1});
                setLaundryAmount(laundryAmount - prices.laundry.extraServices[3].price);
            }
        }else if(val === 'blanket'){
            if(extraLaundryItems.blanket === 0){
                return;
            }else{
                setExtraLaundryItems({...extraLaundryItems, blanket: extraLaundryItems.blanket-1});
                setLaundryAmount(laundryAmount - prices.laundry.extraServices[2].price);
            }
        }else if(val === 'duvet'){
            if(extraLaundryItems.duvet === 0){
                return;
            }else{
                setExtraLaundryItems({...extraLaundryItems, duvet: extraLaundryItems.duvet-1});
                setLaundryAmount(laundryAmount - prices.laundry.extraServices[5].price);
            }
        }else if(val === 'curtain'){
            if(extraLaundryItems.curtain === 0){
                return;
            }else{
                setExtraLaundryItems({...extraLaundryItems, curtain: extraLaundryItems.curtain-1});
                setLaundryAmount(laundryAmount - prices.laundry.extraServices[4].price);
            }
        }else{
            if(extraLaundryItems.suit === 0){
                return;
            }else{
                setExtraLaundryItems({...extraLaundryItems, suit: extraLaundryItems.suit-1});
                setLaundryAmount(laundryAmount - prices.laundry.extraServices[6].price);
            }
        }
    }
    const laundryExtrasIncrease = (val: any) => {
        if(val === 'towel'){
            setExtraLaundryItems({...extraLaundryItems, towel: extraLaundryItems.towel + 1});
            setLaundryAmount(laundryAmount + prices.laundry.extraServices[0].price);
        }else if(val === 'bedSheet'){
            setExtraLaundryItems({...extraLaundryItems, bedsheet: extraLaundryItems.bedsheet + 1})
            setLaundryAmount(laundryAmount + prices.laundry.extraServices[1].price);
        }else if(val === 'agbada'){
            setExtraLaundryItems({...extraLaundryItems, agbada: extraLaundryItems.agbada + 1})
            setLaundryAmount(laundryAmount + prices.laundry.extraServices[3].price);
        }else if(val === 'blanket'){
            setExtraLaundryItems({...extraLaundryItems, blanket: extraLaundryItems.blanket + 1})
            setLaundryAmount(laundryAmount + prices.laundry.extraServices[2].price);
        }else if(val === 'duvet'){
            setExtraLaundryItems({...extraLaundryItems, duvet: extraLaundryItems.duvet + 1})
            setLaundryAmount(laundryAmount + prices.laundry.extraServices[5].price);
        }else if(val === 'curtain'){
            setExtraLaundryItems({...extraLaundryItems, curtain: extraLaundryItems.curtain + 1})
            setLaundryAmount(laundryAmount + prices.laundry.extraServices[4].price);
        }else{
            setExtraLaundryItems({...extraLaundryItems, suit: extraLaundryItems.suit + 1})
            setLaundryAmount(laundryAmount + prices.laundry.extraServices[6].price);
        }
    }    
    const laundryExtrasDropdown = () => setExtrasDropdown(!extrasDropdown);

    // Food Functions
    const foodFrequencyChange = (e: any) => {
        setSelectedFoodFrequency(e.target.value);
        setFoodQuantity(1);
        if(e.target.value === "daily"){
            if(dailyDelivery === "Monday-Friday"){
                setFoodAmount(prices.food.frequency.daily.mon_fri);
            }else{
                setFoodAmount(prices.food.frequency.daily.mon_sat);
            }
        }else if(e.target.value === "weekly"){
            setFoodAmount(prices.food.frequency.weekly);
        }else{
            setFoodAmount(prices.food.frequency.twice_a_week);
        }
    }
    const foodQtyIncrement = () => {
        setFoodQuantity(foodQuantity + 1);       
        if(selectedFoodFrequency === "daily"){
            if(dailyDelivery === "Monday-Friday"){
                setFoodAmount(foodAmount + (prices.food.frequency.daily.mon_fri));
            }else{
                setFoodAmount(foodAmount + (prices.food.frequency.daily.mon_sat));
            }
        }else if(selectedFoodFrequency === "weekly"){
            setFoodAmount(foodAmount + prices.food.frequency.weekly);
        }else {
            setFoodAmount(foodAmount + prices.food.frequency.twice_a_week);
        }
        
    }    
    const foodQtyDecrement = () => {
        if(foodQuantity === 1){
            return;
        }else{
            setFoodQuantity(foodQuantity-1);

            if(selectedFoodFrequency === "daily"){
                if(dailyDelivery === "Monday-Friday"){
                    setFoodAmount(foodAmount - prices.food.frequency.daily.mon_fri);
                }else{
                    setFoodAmount(foodAmount - prices.food.frequency.daily.mon_sat)
                }
            }else if(selectedFoodFrequency === "weekly"){
                setFoodAmount(foodAmount - prices.food.frequency.weekly);
            }else {
                setFoodAmount(foodAmount - prices.food.frequency.twice_a_week);
            }
        }
    }
    const foodDailyDeliverySelection = (e: any) => {
        setDailyDelivery(e.target.value);
        setFoodQuantity(1);
        if(e.target.value === "Monday-Friday"){
            setFoodAmount(prices.food.frequency.daily.mon_fri);
        }else{
            setFoodAmount(prices.food.frequency.daily.mon_sat)
        }
    }
    const foodWeeklyDeliverySelection = (e: any) => {
        setWeeklyDelivery(e.target.value);
    }
    const foodTwiceAWeekDeliverySelection = (e: any) => {
        const {mon, tue, wed, thu, fri, sat} = twiceAWeekDelivery; 

        if(e.target.value === "Mon"){
            if((tue && wed) || (tue && thu) || (tue && fri) || (tue && sat) || (wed && thu) || (wed && fri) || (wed && sat) || (thu && fri) || (thu && sat) || (fri && sat)){
                return;
            }else{                
                if(mon){                
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, mon: false });
                }else{
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, mon: true });
                }
            }
        }else if(e.target.value === "Tue"){            
            if((mon && wed) || (mon && thu) || (mon && fri) || (mon && sat) || (wed && thu) || (wed && fri) || (wed && sat) || (thu && fri) || (thu && sat) || (fri && sat)){
                return;
            }else{                
                if(tue){                
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, tue: false });
                }else{
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, tue: true });
                }
            }
        }else if(e.target.value === "Wed"){            
            if((tue && mon) || (tue && thu) || (tue && fri) || (tue && sat) || (mon && thu) || (mon && fri) || (mon && sat) || (thu && fri) || (thu && sat) || (fri && sat)){
                return;
            }else{                
                if(wed){                
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, wed: false });
                }else{
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, wed: true });
                }
            }
        }else if(e.target.value === "Thu"){            
            if((tue && wed) || (tue && mon) || (tue && fri) || (tue && sat) || (wed && mon) || (wed && fri) || (wed && sat) || (mon && fri) || (mon && sat) || (fri && sat)){
                return;
            }else{                
                if(thu){                
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, thu: false });
                }else{
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, thu: true });
                }
            }
        }else if(e.target.value === "Fri"){            
            if((tue && wed) || (tue && thu) || (tue && mon) || (tue && sat) || (wed && thu) || (wed && mon) || (wed && sat) || (thu && mon) || (thu && sat) || (mon && sat)){
                return;
            }else{                
                if(fri){                
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, fri: false });
                }else{
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, fri: true });
                }
            }
        }else{            
            if((tue && wed) || (tue && thu) || (tue && fri) || (tue && mon) || (wed && thu) || (wed && fri) || (wed && mon) || (thu && fri) || (thu && mon) || (fri && mon)){
                return;
            }else{                
                if(sat){                
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, sat: false });
                }else{
                    setTwiceAWeekDelivery({...twiceAWeekDelivery, sat: true });
                }
            }
        }
    }

    // Cleaning Functions
    const cleaningTypeChange = (e: any) => { 
        setCleaningType(e.target.value);

        if(e.target.value === "regular"){
            setShowFrequency(true);
            setCleaningExtras({bedroom: 1,
                livingroom: 1,
                kitchen: 1,
                store: 0,
                outdoor: 0,
                cleaning: 0})
            if(cleaningFrequency === "once-a-week"){
                setCleaningAmount(prices.cleaning.type.regular.once_a_week);
            }else if(cleaningFrequency === "every-two-weeks"){
                setCleaningAmount(prices.cleaning.type.regular.every_two_weeks);
            }else{
                setCleaningAmount(prices.cleaning.type.regular.once_a_month);
            }
        }else if(e.target.value === 'intense'){
            setShowFrequency(true);
            setCleaningExtras({bedroom: 1,
                livingroom: 1,
                kitchen: 1,
                store: 0,
                outdoor: 0,
                cleaning: 0})
            if(cleaningFrequency === "once-a-week"){
                setCleaningAmount(prices.cleaning.type.intense.once_a_week);
            }else if(cleaningFrequency === "every-two-weeks"){
                setCleaningAmount(prices.cleaning.type.intense.every_two_weeks);
            }else{
                setCleaningAmount(prices.cleaning.type.intense.once_a_month);
            }
        }else {
            setShowFrequency(false);
            setCleaningExtras({bedroom: 1,
                livingroom: 1,
                kitchen: 1,
                store: 0,
                outdoor: 0,
                cleaning: 0})
            setCleaningAmount(prices.cleaning.type.post_construction);
        }
    }
    const cleaningFrequencyChange = (e: any) => {
        setCleaningFrequency(e.target.value);

        if(e.target.value === "once-a-week"){            
            setCleaningExtras({bedroom: 1, livingroom: 1, kitchen: 1, store: 0, outdoor: 0, cleaning: 0})
            if(cleaningType === "regular"){
                setCleaningAmount(prices.cleaning.type.regular.once_a_week);
            }else {
                setCleaningAmount(prices.cleaning.type.intense.once_a_week);
            }
        }else if(e.target.value === "every-two-weeks"){
            setCleaningExtras({bedroom: 1, livingroom: 1, kitchen: 1, store: 0, outdoor: 0, cleaning: 0})
            if(cleaningType === "regular"){
                setCleaningAmount(prices.cleaning.type.regular.every_two_weeks);
            }else{
                setCleaningAmount(prices.cleaning.type.intense.every_two_weeks);
            }
        }else{            
            setCleaningExtras({bedroom: 1, livingroom: 1, kitchen: 1, store: 0, outdoor: 0, cleaning: 0})
            if(cleaningType === "regular"){
                setCleaningAmount(prices.cleaning.type.regular.once_a_month);
            }else{
                setCleaningAmount(prices.cleaning.type.intense.once_a_month);
            }
        }
    }  
    const cleaningExtraServiceDropdown = () => setCleaningExtrasDropdown(!cleaningExtrasDropdown);

    const cleaningExtrasDecrease = (val: any) => {
        if(val === 'bedroom'){
            if(cleaningExtras.bedroom === 1){
                return;
            }else{
                setCleaningExtras({...cleaningExtras, bedroom: cleaningExtras.bedroom-1});
                // Reduce bedroom price depending on tpe and frequency
                if(cleaningType === "post-construction"){
                    setCleaningAmount(cleaningAmount - 87000);                
                }else{ 
                    if(cleaningFrequency === "once-a-week"){
                        setCleaningAmount(cleaningAmount - prices.cleaning.homeExtras[0].price);
                    }else if(cleaningFrequency === "every-two-weeks"){
                        setCleaningAmount(cleaningAmount - (prices.cleaning.homeExtras[0].price / 2));
                    }else{
                        setCleaningAmount(cleaningAmount - (prices.cleaning.homeExtras[0].price / 4));
                    }
                }
            }

        }else if(val === 'livingroom'){
            if(cleaningExtras.livingroom === 1){
                return;
            }else{
                setCleaningExtras({...cleaningExtras, livingroom: cleaningExtras.livingroom-1});
                setCleaningAmount(cleaningAmount - prices.cleaning.homeExtras[1].price);
            }        
        }else if(val === 'kitchen'){
            if(cleaningExtras.kitchen === 1){
                return;
            }else{
                setCleaningExtras({...cleaningExtras, kitchen: cleaningExtras.kitchen-1});
                setCleaningAmount(cleaningAmount - prices.cleaning.homeExtras[2].price);
            }
        }else if(val === 'store'){
            if(cleaningExtras.store === 0){
                return;
            }else{
                setCleaningExtras({...cleaningExtras, store: cleaningExtras.store-1});
                setCleaningAmount(cleaningAmount - prices.cleaning.homeExtras[3].price);
            }
        }else{
            if(cleaningExtras.outdoor === 0){
                return;
            }else{
                setCleaningExtras({...cleaningExtras, outdoor: cleaningExtras.outdoor-1});
                setCleaningAmount(cleaningAmount - prices.cleaning.homeExtras[4].price);
            }
        }
    }
    const cleaningExtrasIncrease = (val: any) => {
        if(val === 'bedroom'){
            setCleaningExtras({...cleaningExtras, bedroom: cleaningExtras.bedroom+1});
            // increase bedroom price depending on tpe and frequency
            if(cleaningType === "post-construction"){
                setCleaningAmount(cleaningAmount + 87000);                
            }else{ 
                if(cleaningFrequency === "once-a-week"){
                    setCleaningAmount(cleaningAmount + prices.cleaning.homeExtras[0].price);
                }else if(cleaningFrequency === "every-two-weeks"){
                    setCleaningAmount(cleaningAmount + (prices.cleaning.homeExtras[0].price / 2));
                }else{
                    setCleaningAmount(cleaningAmount + (prices.cleaning.homeExtras[0].price / 4));
                }
            }
        }else if(val === 'livingroom'){            
            setCleaningExtras({...cleaningExtras, livingroom: cleaningExtras.livingroom+1});
            setCleaningAmount(cleaningAmount + prices.cleaning.homeExtras[1].price);       
        }else if(val === 'kitchen'){
            setCleaningExtras({...cleaningExtras, kitchen: cleaningExtras.kitchen+1});
            setCleaningAmount(cleaningAmount + prices.cleaning.homeExtras[2].price);
        }else if(val === 'store'){
            setCleaningExtras({...cleaningExtras, store: cleaningExtras.store+1});
            setCleaningAmount(cleaningAmount + prices.cleaning.homeExtras[3].price);
        }else{
            setCleaningExtras({...cleaningExtras, outdoor: cleaningExtras.outdoor+1});
            setCleaningAmount(cleaningAmount + prices.cleaning.homeExtras[4].price);
        }
    }


    return(
        <>
            <PricingListWrapper>
                {
                laundry && !cleaning && !food ?
                    <LaundryPlan 
                        handleLaundryBagIncrease={laundryBagIncrement} 
                        handleLaundryBagDecrease={laundryBagDecrement}  
                        handleServiceType={laundryTypeChange}
                        handleFrequency={laundryFrequencyChange} 
                        handleExtraItemsIncrease={laundryExtrasIncrease}
                        handleExtraItemsDecrease={laundryExtrasDecrease}
                        handleExtraServiceDropdown={laundryExtrasDropdown}
                        extrasDropdown={extrasDropdown}
                        laundrybagValue={laundrybagValue}
                        extraLaundryItems={extraLaundryItems}
                        laundryAmount={laundryAmount} /> :
                !laundry && cleaning && !food ?
                    <CleaningPlan
                        cleaningAmount={cleaningAmount} 
                        showFrequency={showFrequency} 
                        handleCleaningTypeChange={cleaningTypeChange} 
                        cleaningExtras={cleaningExtras} 
                        extrasDropdown={cleaningExtrasDropdown} 
                        handleCleaningFrequencyChange={cleaningFrequencyChange} 
                        handleExtraServiceDropdown={cleaningExtraServiceDropdown} 
                        handleCleaningExtrasDecrease={cleaningExtrasDecrease} 
                        handleCleaningExtrasIncrease={cleaningExtrasIncrease} /> :
                !laundry && !cleaning && food ?
                    <FoodPlan
                        handleFrequencyChange={foodFrequencyChange}
                        handleDecrement={foodQtyDecrement}
                        handleIncrement={foodQtyIncrement}
                        handleDailyDeliverySelection={foodDailyDeliverySelection} 
                        handleWeeklyDeliverySelection={foodWeeklyDeliverySelection} 
                        handleTwiceAWeekDeliverySelection={foodTwiceAWeekDeliverySelection}
                        foodQuantity={foodQuantity}
                        selectedFrequency={selectedFoodFrequency}
                        dailyDelivery={dailyDelivery}
                        weeklyDelivery={weeklyDelivery}
                        twiceAWeekDelivery={twiceAWeekDelivery}
                        foodAmount={foodAmount} /> :
                laundry && cleaning && !food ?
                    <>
                        <LaundryPlan 
                            handleLaundryBagIncrease={laundryBagIncrement} 
                            handleLaundryBagDecrease={laundryBagDecrement}  
                            handleServiceType={laundryTypeChange}
                            handleFrequency={laundryFrequencyChange} 
                            handleExtraItemsIncrease={laundryExtrasIncrease}
                            handleExtraItemsDecrease={laundryExtrasDecrease}
                            handleExtraServiceDropdown={laundryExtrasDropdown}
                            extrasDropdown={extrasDropdown}
                            laundrybagValue={laundrybagValue}
                            extraLaundryItems={extraLaundryItems}
                            laundryAmount={laundryAmount} /> 
                        <CleaningPlan 
                            bTop={"none"} mTop={"-7px"} bRadius={"0"}
                            cleaningAmount={cleaningAmount} 
                            showFrequency={showFrequency} 
                            handleCleaningTypeChange={cleaningTypeChange} 
                            cleaningExtras={cleaningExtras} 
                            extrasDropdown={cleaningExtrasDropdown} 
                            handleCleaningFrequencyChange={cleaningFrequencyChange} 
                            handleExtraServiceDropdown={cleaningExtraServiceDropdown} 
                            handleCleaningExtrasDecrease={cleaningExtrasDecrease} 
                            handleCleaningExtrasIncrease={cleaningExtrasIncrease} />
                    </>   :  
                laundry && !cleaning && food ?
                    <>
                        <LaundryPlan 
                            handleLaundryBagIncrease={laundryBagIncrement} 
                            handleLaundryBagDecrease={laundryBagDecrement}  
                            handleServiceType={laundryTypeChange}
                            handleFrequency={laundryFrequencyChange} 
                            handleExtraItemsIncrease={laundryExtrasIncrease}
                            handleExtraItemsDecrease={laundryExtrasDecrease}
                            handleExtraServiceDropdown={laundryExtrasDropdown}
                            extrasDropdown={extrasDropdown}
                            laundrybagValue={laundrybagValue}
                            extraLaundryItems={extraLaundryItems}
                            laundryAmount={laundryAmount} /> 
                        <FoodPlan                    
                            bTop={"none"} mTop={"-7px"} bRadius={"0"} 
                            handleFrequencyChange={foodFrequencyChange}
                            handleDecrement={foodQtyDecrement}
                            handleIncrement={foodQtyIncrement}
                            handleDailyDeliverySelection={foodDailyDeliverySelection} 
                            handleWeeklyDeliverySelection={foodWeeklyDeliverySelection} 
                            handleTwiceAWeekDeliverySelection={foodTwiceAWeekDeliverySelection}
                            foodQuantity={foodQuantity}
                            selectedFrequency={selectedFoodFrequency}
                            dailyDelivery={dailyDelivery}
                            weeklyDelivery={weeklyDelivery}
                            twiceAWeekDelivery={twiceAWeekDelivery}
                            foodAmount={foodAmount} />
                    </>   :
                !laundry && cleaning && food ?
                    <>
                        <FoodPlan 
                            handleFrequencyChange={foodFrequencyChange}
                            handleDecrement={foodQtyDecrement}
                            handleIncrement={foodQtyIncrement}
                            handleDailyDeliverySelection={foodDailyDeliverySelection} 
                            handleWeeklyDeliverySelection={foodWeeklyDeliverySelection} 
                            handleTwiceAWeekDeliverySelection={foodTwiceAWeekDeliverySelection}
                            foodQuantity={foodQuantity}
                            selectedFrequency={selectedFoodFrequency}
                            dailyDelivery={dailyDelivery}
                            weeklyDelivery={weeklyDelivery}
                            twiceAWeekDelivery={twiceAWeekDelivery}
                            foodAmount={foodAmount} />
                        <CleaningPlan 
                            bTop={"none"} mTop={"-7px"} bRadius={"0"}
                            cleaningAmount={cleaningAmount} 
                            showFrequency={showFrequency} 
                            handleCleaningTypeChange={cleaningTypeChange} 
                            cleaningExtras={cleaningExtras} 
                            extrasDropdown={cleaningExtrasDropdown} 
                            handleCleaningFrequencyChange={cleaningFrequencyChange} 
                            handleExtraServiceDropdown={cleaningExtraServiceDropdown} 
                            handleCleaningExtrasDecrease={cleaningExtrasDecrease} 
                            handleCleaningExtrasIncrease={cleaningExtrasIncrease}  />
                    </>   :
                laundry && cleaning && food ?
                    <>
                        <LaundryPlan 
                            handleLaundryBagIncrease={laundryBagIncrement} 
                            handleLaundryBagDecrease={laundryBagDecrement}  
                            handleServiceType={laundryTypeChange}
                            handleFrequency={laundryFrequencyChange} 
                            handleExtraItemsIncrease={laundryExtrasIncrease}
                            handleExtraItemsDecrease={laundryExtrasDecrease}
                            handleExtraServiceDropdown={laundryExtrasDropdown}
                            extrasDropdown={extrasDropdown}
                            laundrybagValue={laundrybagValue}
                            extraLaundryItems={extraLaundryItems}
                            laundryAmount={laundryAmount} /> 
                        <FoodPlan bTop={"none"} mTop={"-7px"} bRadius={"0"} 
                            handleFrequencyChange={foodFrequencyChange}
                            handleDecrement={foodQtyDecrement}
                            handleIncrement={foodQtyIncrement}
                            handleDailyDeliverySelection={foodDailyDeliverySelection} 
                            handleWeeklyDeliverySelection={foodWeeklyDeliverySelection} 
                            handleTwiceAWeekDeliverySelection={foodTwiceAWeekDeliverySelection}
                            foodQuantity={foodQuantity}
                            selectedFrequency={selectedFoodFrequency}
                            dailyDelivery={dailyDelivery}
                            weeklyDelivery={weeklyDelivery}
                            twiceAWeekDelivery={twiceAWeekDelivery}
                            foodAmount={foodAmount}  />
                        <CleaningPlan 
                            bTop={"none"} mTop={"-7px"} bRadius={"0"}
                            cleaningAmount={cleaningAmount} 
                            showFrequency={showFrequency} 
                            handleCleaningTypeChange={cleaningTypeChange} 
                            cleaningExtras={cleaningExtras} 
                            extrasDropdown={cleaningExtrasDropdown} 
                            handleCleaningFrequencyChange={cleaningFrequencyChange} 
                            handleExtraServiceDropdown={cleaningExtraServiceDropdown} 
                            handleCleaningExtrasDecrease={cleaningExtrasDecrease} 
                            handleCleaningExtrasIncrease={cleaningExtrasIncrease} />
                    </>   
                : null
                }            
                <TotalAmount  
                    bTop={"none"} mTop={"-7px"} bRadius={"0"}
                    laundry={laundry}  cleaning={cleaning}  food={food}
                    laundryAmount={laundryAmount} cleaningAmount={cleaningAmount} foodAmount={foodAmount}
                    totalAmount={totalAmount} />
                
                <div className="proceed-to-payment_text" onClick={() => setInfoCollectionModal(true)}>Proceed to Payment &rarr;</div>
            </PricingListWrapper>
            {infoCollectionModal ? 
                <UserInfoModal
                    closeModal={<div onClick={() => setInfoCollectionModal(false)}><AiFillCloseCircle /></div>}
                    isModalOpen={infoCollectionModal}
                    services={{laundry, food, cleaning}}
                    laundryData={{laundryAmount, laundrybagValue, serviceType, selectedLaundryFrequency, extraLaundryItems}}
                    foodData={{foodAmount, foodQuantity, selectedFoodFrequency}}
                    foodDelivery={{dailyDelivery, weeklyDelivery, twiceAWeekDelivery}}
                    cleaningData={{cleaningFrequency, cleaningType, cleaningExtras, cleaningAmount}}
                    totalAmount={totalAmount}  />
            :null}
        </>

    )
}

const LaundryPlan = (props: any) => {  
    const {laundrybagValue, handleLaundryBagIncrease, extraLaundryItems, laundryAmount, handleLaundryBagDecrease, handleServiceType, handleFrequency, handleExtraServiceDropdown, extrasDropdown, handleExtraItemsDecrease, handleExtraItemsIncrease} = props;  

    return(
        <LaundryPlanWrapper>
            <h4>Laundry Plan</h4>
            <section>
                <div className="type-frequency-selectors">
                    <div className='type'>
                        <label>Type</label>
                        <select onChange={handleServiceType}>
                            <option value="wash-iron">Wash & Iron</option>
                            <option value="wash-fold">Wash & Fold</option>
                        </select>
                    </div>
                    <div className='frequency'>
                        <label>Frequency</label>
                        <select onChange={handleFrequency}>
                            <option value="once-a-week">Once a week</option>
                            <option value="every-two-weeks">Every two weeks</option>
                            <option value="omce-a-month">Once a month</option>
                        </select>
                    </div>                    
                </div>
                <div className='increase-decrease-section'>
                    <label>Number of laundry bags (10 items)</label>
                    <div>
                        <button onClick={handleLaundryBagDecrease} className="decrease">
                            <img src={Minus} alt="minus" />
                        </button>
                        <input type="number" readOnly={true} value={laundrybagValue} />
                        <button onClick={handleLaundryBagIncrease} className="increase">
                            <img src={Plus} alt="plus" />
                        </button>
                    </div>
                </div>
                <div className='extras-section'>
                    <label>Extra laundry items</label>
                    <div className='extras-item-list-container'>
                        <div className='extras-item-list' onClick={handleExtraServiceDropdown}>
                            <p>{ extraLaundryItems.towel} Towel/ { extraLaundryItems.bedsheet} Bedsheet/ { extraLaundryItems.blanket} Blanket/ { extraLaundryItems.agbada} Agbada/ { extraLaundryItems.curtain} Curtain/ { extraLaundryItems.duvet} Duvet/ { extraLaundryItems.suit} Suit</p> <span className='caret-icon'>{extrasDropdown ?<FaAngleUp /> : <FaAngleDown/> }</span>
                        </div>
                        <ul className={extrasDropdown ? 'show' : 'hide'}>                        
                        <ExtraItemsContainer>
                            { prices.laundry.extraServices.map((extraService: any, index: number) => (
                                <li key={index}>
                                    <label>{extraService.displayText}</label> 
                                    <div className='extras-count'>
                                        <button className="" onClick={() => handleExtraItemsDecrease(extraService.item)}>
                                            <img src={Minus} alt="minus" />
                                        </button>&nbsp;&nbsp;
                                        <p>
                                            {
                                            extraService.item === 'towel' ?
                                            extraLaundryItems.towel: extraService.item === 'bedSheet' ? 
                                            extraLaundryItems.bedsheet: extraService.item === 'agbada' ?
                                            extraLaundryItems.agbada: extraService.item === 'blanket' ?
                                            extraLaundryItems.blanket: extraService.item === 'duvet' ?
                                            extraLaundryItems.duvet: extraService.item === 'curtain' ?
                                            extraLaundryItems.curtain: extraService.item === 'suit' ?
                                            extraLaundryItems.suit: null
                                            }
                                        </p>&nbsp;&nbsp;
                                        <button className="" onClick={() => handleExtraItemsIncrease(extraService.item)}>
                                            <img src={Plus} alt="plus" />
                                        </button>
                                    </div> 
                                </li>
                            ))}
                        
                        </ExtraItemsContainer>
                        </ul>
                    </div>
                </div>
                <div className='amount-section'>
                    <p>Laundry amount</p>
                    <p>{CurrencyFormatter.format(laundryAmount).replace(/(\.|,)00$/g, '')}/month</p>
                </div>
            </section>
        </LaundryPlanWrapper>
    )
}

const FoodPlan = (props: any) => {
    const {bTop, mTop, bRadius, foodAmount, foodQuantity, selectedFrequency, dailyDelivery, weeklyDelivery, twiceAWeekDelivery, handleFrequencyChange, handleDecrement, handleIncrement, handleDailyDeliverySelection, handleWeeklyDeliverySelection, handleTwiceAWeekDeliverySelection} = props
  
    const handleFoodQuantityChange = (e: any) => console.log(e.target.value);

    return(
        <FoodPlanWrapper style={{borderTop: bTop, marginTop: mTop, borderTopLeftRadius: bRadius, borderTopRightRadius: bRadius}}>
            <h4>Food Plan</h4>
            <div className="frequency-and-number-of-meals">
                <div className='frequency'>
                    <label>Frequency</label>
                    <select onChange={handleFrequencyChange}>
                        <option value="daily">Daily</option>
                        <option value="weekly">Weekly</option>
                        <option value="twice-a-week">Twice a Week</option>
                    </select>
                </div>
                <div className='number-of-meals'>
                    <label>Number of meals per delivery</label>
                    <div>            
                        <button onClick={handleDecrement} className="decrease">
                            <img src={Minus} alt="minus" />
                        </button>
                        <input type="number" readOnly={true} value={foodQuantity} onChange={handleFoodQuantityChange} />
                        <button onClick={handleIncrement} className="increase">
                            <img src={Plus} alt="plus" />
                        </button>
                    </div>
                </div>
            </div>
            <div className='delivery-days'>
                <label className='delivery-days-label'>Delivery days</label>
                { selectedFrequency === "daily" ?
                    <DailyDeliveryWrapper>
                        <div className={dailyDelivery === "Monday-Friday"? "selected" : ""}>
                            <input type="checkbox" id="monday-friday" value="Monday-Friday" onClick={handleDailyDeliverySelection} />
                            <label htmlFor="monday-friday">Monday-Friday</label>
                        </div>
                        <div className={dailyDelivery === "Monday-Saturday"? "selected" : ""}>
                            <input type="checkbox" id="monday-saturday" value="Monday-Saturday" onClick={handleDailyDeliverySelection} />
                            <label htmlFor="monday-saturday" >Monday-Saturday</label>
                        </div>
                    </DailyDeliveryWrapper>
                : selectedFrequency === "weekly" ?
                    <WeeklyDeliveryWrapper>
                        <div className={weeklyDelivery === "Mon"? "selected": ""}>
                            <input type="checkbox" id="Mon" value="Mon" onClick={handleWeeklyDeliverySelection} />
                            <label htmlFor="Mon">Mon</label>
                        </div>
                        <div className={weeklyDelivery === "Tue"? "selected": ""}>
                            <input type="checkbox" id="Tue" value="Tue" onClick={handleWeeklyDeliverySelection} />
                            <label htmlFor="Tue" >Tue</label>
                        </div>
                        <div className={weeklyDelivery === "Wed"? "selected": ""}>
                            <input type="checkbox" id="Wed" value="Wed" onClick={handleWeeklyDeliverySelection} />
                            <label htmlFor="Wed" >Wed</label>
                        </div>
                        <div className={weeklyDelivery === "Thu"? "selected": ""}>
                            <input type="checkbox" id="Thu" value="Thu" onClick={handleWeeklyDeliverySelection} />
                            <label htmlFor="Thu" >Thu</label>
                        </div>
                        <div className={weeklyDelivery === "Fri"? "selected": ""}>
                            <input type="checkbox" id="Fri" value="Fri" onClick={handleWeeklyDeliverySelection} />
                            <label htmlFor="Fri" >Fri</label>
                        </div>
                        <div className={weeklyDelivery === "Sat"? "selected": ""}>
                            <input type="checkbox" id="Sat" value="Sat" onClick={handleWeeklyDeliverySelection} />
                            <label htmlFor="Sat" >Sat</label>
                        </div>
                    </WeeklyDeliveryWrapper>
                :   
                    <TwicePerWeekDelivery>
                        <div className={twiceAWeekDelivery.mon? "selected": ""}>
                            <input type="checkbox" id="Mon" value="Mon" onClick={handleTwiceAWeekDeliverySelection} />
                            <label htmlFor="Mon">Mon</label>
                        </div>
                        <div className={twiceAWeekDelivery.tue? "selected": ""}>
                            <input type="checkbox" id="Tue" value="Tue" onClick={handleTwiceAWeekDeliverySelection} />
                            <label htmlFor="Tue" >Tue</label>
                        </div>
                        <div className={twiceAWeekDelivery.wed? "selected": ""}>
                            <input type="checkbox" id="Wed" value="Wed" onClick={handleTwiceAWeekDeliverySelection} />
                            <label htmlFor="Wed" >Wed</label>
                        </div>
                        <div className={twiceAWeekDelivery.thu? "selected": ""}>
                            <input type="checkbox" id="Thu" value="Thu" onClick={handleTwiceAWeekDeliverySelection} />
                            <label htmlFor="Thu" >Thu</label>
                        </div>
                        <div className={twiceAWeekDelivery.fri? "selected": ""}>
                            <input type="checkbox" id="Fri" value="Fri" onClick={handleTwiceAWeekDeliverySelection} />
                            <label htmlFor="Fri" >Fri</label>
                        </div>
                        <div className={twiceAWeekDelivery.sat? "selected": ""}>
                            <input type="checkbox" id="Sat" value="Sat" onClick={handleTwiceAWeekDeliverySelection} />
                            <label htmlFor="Sat" >Sat</label>
                        </div>
                    </TwicePerWeekDelivery>
                }   
            </div>
            <div className='amount-section'>
                <p>Food amount</p>
                <p>{CurrencyFormatter.format(foodAmount).replace(/(\.|,)00$/g, '')}/month</p>
            </div>
        </FoodPlanWrapper>
    )
}

const CleaningPlan = (props: any) => {
    const {bTop, mTop, bRadius, cleaningAmount, showFrequency, handleCleaningTypeChange, cleaningExtras, extrasDropdown, handleCleaningFrequencyChange, handleExtraServiceDropdown, handleCleaningExtrasDecrease, handleCleaningExtrasIncrease} = props;

    return(
        <CleaningPlanWrapper style={{borderTop: bTop, marginTop: mTop, borderTopLeftRadius: bRadius, borderTopRightRadius: bRadius}}>
            <h4>Cleaning Plan</h4>
            <div className="type-frequency-selectors">
                <div className='type'>
                    <label>Type</label>
                    <select onChange={handleCleaningTypeChange}>
                        <option value="regular">Regular</option>
                        <option value="intense">Intense</option>
                        <option value="post-construction">Post-construction</option>
                    </select>
                </div> 
               {showFrequency ?
                <div className='frequency'>
                    <label>Frequency</label>
                    <select onChange={handleCleaningFrequencyChange}>
                        <option value="once-a-week">Once a week</option>
                        <option value="every-two-weeks">Every two weeks</option>
                        <option value="omce-a-month">Once a month</option>
                    </select>
                </div> : null
                }                  
            </div>
            <div className='extras-section'>
                    <label>Description of your home</label>
                    <div className='extras-item-list-container'>
                        <div className='extras-item-list' onClick={handleExtraServiceDropdown}>
                            <p>{cleaningExtras.bedroom} Bedrooms, {cleaningExtras.livingroom} Living Rooms / Dining Areas, {cleaningExtras.kitchen} Kitchen, {cleaningExtras.store} Study / Store, {cleaningExtras.outdoor} Outdoor / Balcony</p> <span className='caret-icon'>{extrasDropdown ?<FaAngleUp /> :<FaAngleDown/> }</span>
                        </div>
                        <ul className={extrasDropdown ? 'show' : 'hide'}>                        
                        <ExtraItemsContainer>
                            {prices.cleaning.homeExtras.map((cleaningService: any, index: number) => (
                                <li key={index}>
                                    <label>{cleaningService.displayText}</label> 
                                    <div className='extras-count'>
                                        <button className="" onClick={() => handleCleaningExtrasDecrease(cleaningService.item)}>
                                            <img src={Minus} alt="minus" />
                                        </button>&nbsp;&nbsp;
                                        <p>
                                            {
                                            cleaningService.item === 'bedroom' ?
                                            cleaningExtras.bedroom: cleaningService.item === 'livingroom' ? 
                                            cleaningExtras.livingroom: cleaningService.item === 'kitchen' ?
                                            cleaningExtras.kitchen: cleaningService.item === 'store' ?
                                            cleaningExtras.store: cleaningService.item === 'outdoor' ?
                                            cleaningExtras.outdoor: null
                                            }
                                        </p>&nbsp;&nbsp;
                                        <button className="" onClick={() => handleCleaningExtrasIncrease(cleaningService.item)}>
                                            <img src={Plus} alt="plus" />
                                        </button>
                                    </div> 
                                </li>
                            ))}
                        
                        </ExtraItemsContainer>
                        </ul>
                    </div>
                </div>
                <div className='amount-section'>
                    <p>Cleaning amount</p>
                    <p>{CurrencyFormatter.format(cleaningAmount).replace(/(\.|,)00$/g, '')}/month</p>
                </div>
        </CleaningPlanWrapper>
    )
}


const TotalAmount = (props: any) => {
    const {bTop, mTop, bRadius, totalAmount} = props;
    return (
        <TotalAmountWrapper style={{ borderTop: bTop, marginTop: mTop, borderTopLeftRadius: bRadius, borderTopRightRadius: bRadius}}>
            <div>Total Amount</div>
           <div className='amount-section'>                    
                {CurrencyFormatter.format(totalAmount).replace(/(\.|,)00$/g, '')}/month
            </div>
        </TotalAmountWrapper>
    )
}



export const UserInfoModal = (props: any) => {       

    const { closeModal, isModalOpen, services, laundryData, foodData, cleaningData, foodDelivery, totalAmount} = props;
    const { mainStyle, formStyle, formStyleMobile, inputStyle, inputStyleMobile, btnStyle, btnStyleMobile, errorText} = ModalStyle;

    const {laundry, food, cleaning} = services;
    const {laundryAmount, laundrybagValue, serviceType, selectedLaundryFrequency, extraLaundryItems} = laundryData;
    const {foodAmount, foodQuantity, selectedFoodFrequency} = foodData;
    const {cleaningFrequency, cleaningType, cleaningExtras, cleaningAmount} = cleaningData;
    const {dailyDelivery, weeklyDelivery, twiceAWeekDelivery} = foodDelivery;
    const SERVICE_ID = 'service_dkaniuh';
    const TEMPLATE_ID = 'template_mjhvkjo';
    const API_KEYS = 'kgHojWJTfgq5_GX6-';

    
    const [formData, setFormData] = useState({ full_name: '', email: '', phone: '', address: '' }); 
    const [service, setService] = useState({});
    const [modalDisplay, setModalDisplay] = useState(isModalOpen);
    const [mailSendingStatus, setMailSendingStatus] = useState(false);
    const [isAgreed, setIsAgreed] = useState(false)
    const [errorNotification, setErrorNotification] = useState(false);
    
    const realTotalAmount = CurrencyFormatter.format(totalAmount);

    const laundry_info = {
        l_service: 'Laundry',
        laundry_type: serviceType,
        laundry_frequency: selectedLaundryFrequency,
        laundry_bags: laundrybagValue,
        laundry_price: CurrencyFormatter.format(laundryAmount)
    }
    const food_info = {
        f_service: 'Food',
        food_quantity: foodQuantity,
        food_frequency: selectedFoodFrequency,
        food_price: CurrencyFormatter.format(foodAmount)
    }
    const cleaning_info = {
        c_service: 'Cleaning',
        cleaning_type: cleaningType,
        cleaning_frequency: cleaningFrequency,
        cleaning_price: CurrencyFormatter.format(cleaningAmount)
    }

    useEffect(() => {
        if(laundry && !cleaning && !food ){
            let extraItems = []
            for(let key in extraLaundryItems){
                if(extraLaundryItems.hasOwnProperty(key) && extraLaundryItems[key] >= 1){
                    extraItems.push(`${extraLaundryItems[key]} ${key}`);
                }
            }
            setService({...laundry_info, laundry_extra_items: extraItems, realTotalAmount})
       }else if(!laundry && cleaning && !food ){ 
        //  Cleaning 
        let extraSections = []
        for(let key in cleaningExtras){
            if(cleaningExtras.hasOwnProperty(key) && cleaningExtras[key] >= 1){
               extraSections.push(`${cleaningExtras[key]} ${key}`);
            }
        }
            setService({...cleaning_info, cleaning_extras: extraSections, realTotalAmount});
       }else if(!laundry && !cleaning && food ){
           if(selectedFoodFrequency === 'daily'){
                setService({...food_info, food_delivery_days: dailyDelivery, realTotalAmount});
           }else if(selectedFoodFrequency === 'weekly'){            
                setService({...food_info, food_delivery_days: weeklyDelivery, realTotalAmount});
           }else{
               let selectedDays = [];
               for(let key in twiceAWeekDelivery){
                    if(twiceAWeekDelivery.hasOwnProperty(key) && twiceAWeekDelivery[key] === true){
                        if(key === 'mon' || key === 'fri'){ 
                            selectedDays.push(`${key}day`);
                        }else if(key === 'tue'){
                            selectedDays.push(`${key}sday`);
                        }else if(key === 'wed'){
                            selectedDays.push(`${key}nesday`)
                        }else if(key === 'thu'){
                            selectedDays.push(`${key}rsday`)
                        }else{
                            selectedDays.push(`${key}urday`)
                        }
                    }
               }
                setService({...food_info, food_delivery_days: selectedDays, realTotalAmount});
           }
       }else if(laundry && cleaning && !food ){ 
        //    Laundry 
            let extraItems = []
            for(let key in extraLaundryItems){
                if(extraLaundryItems.hasOwnProperty(key) && extraLaundryItems[key] >= 1){
                   extraItems.push(`${extraLaundryItems[key]} ${key}`);
                }
            }
        //  Cleaning 
            let extraSections = []
            for(let key in cleaningExtras){
                if(cleaningExtras.hasOwnProperty(key) && cleaningExtras[key] >= 1){
                   extraSections.push(`${cleaningExtras[key]} ${key}`);
                }
            }
            setService({...laundry_info,  laundry_extra_items: extraItems, ...cleaning_info, cleaning_extras: extraSections, realTotalAmount});
       }else if(laundry && !cleaning && food ){ 
        //    laundry extras
            let extraItems = []
            for(let key in extraLaundryItems){
                if(extraLaundryItems.hasOwnProperty(key) && extraLaundryItems[key] >= 1){
                    extraItems.push(`${extraLaundryItems[key]} ${key}`);
                }
            }
        //  food
           if(selectedFoodFrequency === 'daily'){
                setService({...laundry_info, laundry_extra_items: extraItems, ...food_info, food_delivery_days: dailyDelivery, realTotalAmount})
            }else if(selectedFoodFrequency === 'weekly'){
                setService({...laundry_info, laundry_extra_items: extraItems, ...food_info, food_delivery_days: weeklyDelivery, realTotalAmount})
            }else{ 
                let selectedDays = [];
                for(let key in twiceAWeekDelivery){
                     if(twiceAWeekDelivery.hasOwnProperty(key) && twiceAWeekDelivery[key] === true){
                         if(key === 'mon' || key === 'fri'){ 
                             selectedDays.push(`${key}day`);
                         }else if(key === 'tue'){
                             selectedDays.push(`${key}sday`);
                         }else if(key === 'wed'){
                             selectedDays.push(`${key}nesday`)
                         }else if(key === 'thu'){
                             selectedDays.push(`${key}rsday`)
                         }else{
                             selectedDays.push(`${key}urday`)
                         }
                     }
                }
                setService({...laundry_info, laundry_extra_items: extraItems, ...food_info, food_delivery_days: selectedDays, realTotalAmount})
            }
       }else if(!laundry && cleaning && food ){
            //  Cleaning 
            let extraSections = []
            for(let key in cleaningExtras){
                if(cleaningExtras.hasOwnProperty(key) && cleaningExtras[key] >= 1){
                   extraSections.push(`${cleaningExtras[key]} ${key}`);
                }
            }
            // Food 
            if(selectedFoodFrequency === 'daily'){
                setService({...food_info, food_delivery_days: dailyDelivery, ...cleaning_info, cleaning_extras: extraSections, realTotalAmount})
            }else if(selectedFoodFrequency === 'weekly'){
                setService({...food_info, food_delivery_days: weeklyDelivery, ...cleaning_info, cleaning_extras: extraSections, realTotalAmount})
            }else{
                let selectedDays = [];
                for(let key in twiceAWeekDelivery){
                     if(twiceAWeekDelivery.hasOwnProperty(key) && twiceAWeekDelivery[key] === true){
                         if(key === 'mon' || key === 'fri'){ 
                             selectedDays.push(`${key}day`);
                         }else if(key === 'tue'){
                             selectedDays.push(`${key}sday`);
                         }else if(key === 'wed'){
                             selectedDays.push(`${key}nesday`)
                         }else if(key === 'thu'){
                             selectedDays.push(`${key}rsday`)
                         }else{
                             selectedDays.push(`${key}urday`)
                         }
                     }
                }            
                setService({...food_info, food_delivery_days: selectedDays, ...cleaning_info, cleaning_extras: extraSections, realTotalAmount})
            }
       }else {
        //   laundry extras
            let extraItems = []
            for(let key in extraLaundryItems){
                if(extraLaundryItems.hasOwnProperty(key) && extraLaundryItems[key] >= 1){
                    extraItems.push(`${extraLaundryItems[key]} ${key}`);
                }
            }
        //  Cleaning 
            let extraSections = []
            for(let key in cleaningExtras){
                if(cleaningExtras.hasOwnProperty(key) && cleaningExtras[key] >= 1){
                extraSections.push(`${cleaningExtras[key]} ${key}`);
                }
            }
        //  food 
            if(selectedFoodFrequency === 'daily'){
                setService({...laundry_info, laundry_extra_items: extraItems, ...food_info, food_delivery_days: dailyDelivery, ...cleaning_info, cleaning_extras: extraSections, realTotalAmount});
            }else if(selectedFoodFrequency === 'weekly'){
                setService({...laundry_info, laundry_extra_items: extraItems, ...food_info, food_delivery_days: weeklyDelivery, ...cleaning_info, cleaning_extras: extraSections, realTotalAmount});
            }else{
                let selectedDays = [];
                for(let key in twiceAWeekDelivery){
                     if(twiceAWeekDelivery.hasOwnProperty(key) && twiceAWeekDelivery[key] === true){
                         if(key === 'mon' || key === 'fri'){ 
                             selectedDays.push(`${key}day`);
                         }else if(key === 'tue'){
                             selectedDays.push(`${key}sday`);
                         }else if(key === 'wed'){
                             selectedDays.push(`${key}nesday`)
                         }else if(key === 'thu'){
                             selectedDays.push(`${key}rsday`)
                         }else{
                             selectedDays.push(`${key}urday`)
                         }
                     }
                }            
                setService({...laundry_info, laundry_extra_items: extraItems, ...food_info, food_delivery_days: selectedDays, ...cleaning_info, cleaning_extras: extraSections, realTotalAmount});
            }
       }
       
    }, [laundry, food, cleaning, realTotalAmount ])


    const config = {
        reference: (new Date()).getTime().toString(),
        email: formData.email,
        amount: totalAmount * 100,
        publicKey: public_key,
    };
    
    const onSuccess = (reference: any) => {
        // Implementation for whatever you want to do with reference and after success call.
        console.log(reference);
        send(
            SERVICE_ID,
            TEMPLATE_ID,
            {...formData, ...service, ...reference},
            API_KEYS
        ).then(() => {
            // show success message
            setModalDisplay(false);
            toast(`Order successful! payment details sent to ${formData.email}`, {
                position: "top-right",
                autoClose: 5000,
                type: 'success',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
                });
            setMailSendingStatus(false);
        })
        .catch((err) => {
            setModalDisplay(false);
            toast(`Error: ${err}. Contact Support!`, {
                position: "top-right",
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
                });
            setMailSendingStatus(false);
        });
    };

    // you can call this function anything
    const onClose = () => {
        // implementation for  whatever you want to do when the Paystack dialog closed.
        setModalDisplay(!modalDisplay);
        toast(`Could not complete transaction`, {
                position: "top-right",
                autoClose: 5000,
                type: 'error',
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                progress: undefined
                });
        setMailSendingStatus(false);
        setErrorNotification(false);
        setTimeout(()=> window.location.reload(), 5500)
    }

    const initializePayment = usePaystackPayment(config);

    
    const handleChange = (e: any) => {
        if(e.target.data !== '') setFormData({ ...formData, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e: any) => {
        e.preventDefault();
        if(isAgreed){ 
            setMailSendingStatus(true);
            initializePayment(onSuccess, onClose);   
        }else{
            setErrorNotification(true);
            return;
        }
        
    }
    const handleIsAgreedChange = (e: any) => {
        if(e.target.checked === true){
            setIsAgreed(true);
        }else{
            setIsAgreed(false);
        }
    }

    
    const isMobile = useMediaQuery({
        query: "(max-width: 900px)"
    });

    

    return(  
        <div>
            <Modal
                isOpen={modalDisplay} 
                ariaHideApp={false}
                style={mainStyle}>
                <div style={{display:'flex', justifyContent:'flex-end', fontSize:'20px', color:'#073B4C'}}>{closeModal}</div>
                <div className="modal-content">
                    <h3 style={{textAlign:'center', color:'#073B4C'}}>Payment Details</h3>
                    <form 
                        method='post'
                        style={isMobile ? formStyleMobile : formStyle}
                        onSubmit={handleSubmit}> 
                        <input 
                            type="text" 
                            placeholder="Full name" 
                            style={isMobile ? inputStyleMobile : inputStyle}
                            name="full_name"
                            value={formData.full_name}
                            onChange={handleChange}
                            required />                           
                        <input 
                            type="email" 
                            placeholder="user@example.com" 
                            style={isMobile ? inputStyleMobile : inputStyle}
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required />
                        <input 
                            type="tel" 
                            placeholder="Phone number" 
                            style={isMobile ? inputStyleMobile : inputStyle}
                            name="phone"
                            value={formData.phone}
                            onChange={handleChange}
                            required />
                        <input 
                            type="text"
                            placeholder="Address" 
                            style={isMobile ? inputStyleMobile : inputStyle}
                            name="address"
                            value={formData.address}
                            onChange={handleChange}
                            required /> 
                        <button style={isMobile ? btnStyleMobile :btnStyle} type="submit">{mailSendingStatus ? 'loading...' : 'Submit'}</button>
                        <div>
                            <input id="terms_and_condition" type='checkbox' onChange={handleIsAgreedChange} />&nbsp;
                            <label htmlFor="terms_and_condition" style={errorNotification? errorText: undefined}>I agree to the <a href="https://worka.tawk.help/article/terms-and-conditions" target="_blank" rel="noreferrer">terms and agreement</a></label>
                        </div>
                    </form>
                </div>
            </Modal>
            <ToastContainer />
        </div>         
    );
}
