
export const prices = {
    food: {
        frequency: {
            daily: {
                mon_fri: 62000,
                mon_sat: 74500
            },
            twice_a_week: 21000,
            weekly: 11875,
            }
        },
    laundry: {
            type: {
                    washFold: [{
                        once_a_week: 20000,
                        every_two_weeks: 11000,
                        once_a_month: 5000
                      }],
                    washIron: [{
                        once_a_week: 30000,
                        every_two_weeks: 15000,
                        once_a_month: 7000
                      }]
                },
            extraServices: [{
                item: 'towel',
                displayText: 'Big Size Towel (N500)',
                price: 400
            },
            {
                item: 'bedSheet',
                displayText: 'BedSheet (N750)',
                price: 650
            },
            {
                item: 'blanket',
                displayText: 'Blanket (N750)',
                price: 750
            },
            {
                item: 'agbada',
                displayText: '3 Piece Agbada (N1,500)',
                price: 1500
            },
            {
                item: 'curtain',
                displayText: 'Curtain (N2,000)',
                price: 2000
            },
            {
                item: 'duvet',
                displayText: 'Duvet (N2,500)',
                price: 2500
            },
            {
                item: 'suit',
                displayText: 'A Pair of Suit (N2,500)',
                price: 2500
            }]

        },
    cleaning: {
        type: {
            regular: {
                once_a_week: 64000,
                every_two_weeks: 32000,
                once_a_month: 16000
            },
            intense: {
                once_a_week: 126000,
                every_two_weeks: 63000,
                once_a_month: 31500
            },
            post_construction: 154000
        },
        homeExtras: [
            {
                item: 'bedroom',
                displayText: 'Bedrooms',
                price: 18000
            },
            {
                item: 'livingroom',
                displayText: 'Living Rooms / Dining Areas',
                price: 15000
            },
            {
                item: 'kitchen',
                displayText: 'Kitchen',
                price: 15000
            },
            {
                item: 'store',
                displayText: 'Study / Store',
                price: 10000
            },
            {
                item: 'outdoor',
                displayText: 'Outdoor / Balcony',
                price: 10000
            },
        ]
    }
    
}