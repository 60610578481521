import { useState } from 'react';
import { FaBars, FaTimes } from 'react-icons/fa';
import { Link } from 'react-router-dom';
import { Link as ScrollLink } from "react-scroll";
import { Logo } from '../Logo';
import { Navbar, Wrapper, DropdownWrapper, DropDownitems } from './MobileNavbarStyle';



export const MobileNavbar = () => {
    return(
        <Navbar>
            <Wrapper>
                <Logo height="20px" />
                <DropDown />
            </Wrapper>
        </Navbar>
    )
}

const DropDown = () => {
    const [isCollapsed, setIsCollapsed] = useState(false);

    const handleCollapse = () => setIsCollapsed(!isCollapsed);

    return (
        <DropdownWrapper>
            <span>{ isCollapsed ? <FaTimes onClick={handleCollapse} /> :<FaBars onClick={handleCollapse} />}</span>
            <DropDownitems>
                <ul className={isCollapsed ? "show-dropdown" : "hide-dropdown"}>
                    <li>
                        <ScrollLink
                            to="introSection"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}>Home</ScrollLink> 
                    </li>
                    <li>
                        <ScrollLink
                            to="servicesSection"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}>Services</ScrollLink> 
                    </li>
                    <li >
                        <ScrollLink
                            to="downloadSection"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}>Download</ScrollLink>
                    </li>
                    <li >
                        <ScrollLink
                            to="contactSection"
                            spy={true}
                            smooth={true}
                            offset={-70}
                            duration={500}>Contact Us</ScrollLink> 
                    </li>
                    <li >
                        <Link to="/pricing">Price Plan</Link> 
                    </li>
                </ul>
            </DropDownitems>
        </DropdownWrapper>
    )
}