import { Routes, Route } from 'react-router-dom';
import './assets/css/globalStyle.css';
import { Home, Laundry, Cleaning, Food,  MarketRuns, Pricing } from './pages';

function App() {

  return (
    <div className="App">
      <Routes>
          <Route path='/' element={<Home/> } />
          <Route path='services/'>
            <Route path='cleaning' element={<Cleaning/>} />
            <Route path='laundry'element={<Laundry/>} />
            <Route path='food'element={<Food/>} />
            <Route path='market-runs'element={<MarketRuns />} />
          </Route>
          <Route path='pricing' element={<Pricing/>} />
      </Routes>
    </div>
  );
};

export default App;
