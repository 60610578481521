import styled from "styled-components";

export const Section = styled.section`
    width: 100%;
    margin-top: 150px;      
    scroll-behavior: smooth;

    .trusted_text-col {
        margin-top: -70px;
    }
    // .how-it-works-container {
    //    background-color: #FAFAFA;
    //    padding-top: 100px;
    //    padding-bottom: 50px;
    // }

    .about-us {
        font-size: 35px;
        line-height: 45px;
        text-align: center;        
    }
    .download-section {
        margin-top: 0;
    }

    @media (max-width: 1020px) { 
        .trusted_text-col {
            margin-top: -140px;
        }
        .about-us { 
            margin-top: 85px;
            padding-bottom: 0px;
            line-height: 15px;
        }
`;
export const Col = styled.div`
    max-width: 80%;
    margin: 15px auto 0 auto;
    padding: 15px 0;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-contents: stretch; 

    .card-register {
        margin-top: 0;
    }
    .card-check-service {
        margin-top: 40px;
    }
    .card-sbe {
        margin-top: 100px;
    }
    
    @media (max-width: 1020px) {
        margin-top: 0px;
        
        .trusted_text-col {
            margin-top: -150px;
        }
        .card-register,.card-check-service,.card-sbe {
            margin-top: 0px;
        }
    }
`;
export const Row = styled.div`
    height: 100%; 
    margin: auto 0;

    .intro_text {
        font-weight: 700;
        font-size: 40px;
        line-height: 55px;
        color: #000000;

        span {
            color: #FFCA66;
        }
    }
    .intro_subtext {
        font-weight: 400;
        font-size: 18px;
        line-height: 30px;
        color: var(--light-gray);
    }
    .btns {
        margin-top: 3rem;
        display: flex;
        gap: 1rem;
        margin-left: -1.5rem;

        a {
            text-decoration: none;
            padding: 10px 20px;
            border-radius: 5px;
            font-size: 18px;
            cursor: pointer;
        }
        .worka-it {
            font-family: FibonSans;
        }

        .intro-contact-us{
            cursor: pointer;
            padding: 10px 5px;
            color: var(--dark-green);
            font-size: 18px;
        }
        
    }

    .trusted_text {
        font-size: 23px;
        line-height: 40px;
    }

    @media (max-width: 1023px) {
        flex: 100%;
        .intro_text {            
            font-size: 35px;
            line-height: 50px;
            text-align: center;
            margin-top: -30px;
        }
        .intro_subtext {            
            font-size: 18px;
            text-align: left;
        }
        .btns {
            display: grid;
            justify-content: center;
            margin-left: 0;
            margin-top: 3rem;
            
            .worka-it {
                padding-left: 75px;
                padding-right: 75px;
            }
            .intro-contact-us{
                padding-left: 85px;
                padding-right: 85px;
            }
        }
        .card-item-main {               
            max-width: 300px;
            margin: 0 auto;
            p {
                line-height: 27px;
            }
        }
        .card-item {            
            max-width: 270px;
            height: 200px;
            padding: 30px;
            background: #FFF;
            margin: 2.5rem auto 0 auto;
            box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

            .card-p{ 
                height: 100px;
                margin-top: -10px;
                width: 100%;
                padding: 0;        

                p {
                    line-height: 27px;
                }
            }
        }
        .trusted_text {
            font-size: 18px;
            line-height: 20px;
            text-align: center;
        }
    }
`;
export const ImageContainer = styled.img`
    padding-top: 2rem;
    margin-top: -4.5rem;
    max-height: 700px;
    max-width: 770px;
    min-width: 500px;
    @media (max-width: 1023px) {
        display: none;
    }
`;
export const RatingWrapper = styled.div`
    display: grid;
    justify-content: center;

    h1 {        
        font-size: 40px;
        font-weight: 600;
        margin-left: 30px;
    }
    .star-icon {
        font-size: 30px;
        position: relative;
        top: -25px;
    }
    
    @media (max-width: 1023px) {
        h1 {        
            font-size: 20px;
            margin-left: 0px;
            text-align: center;
        }
        .star-icon {
            font-size: 20px;
            position: relative;
            top: -20px;
        }
    }
`;
export const Service = styled.div`
    display: flex;    
    justify-content: center;
    font-size: 42px;
    font-weight: 600;
    gap: 5px;
    margin: auto 0;

    .service {
        font-family: PoppinsBold;
    }

    @media (max-width: 1023px) {       
        font-size: 20px;
    }
`;
export const Card = styled.div`
    padding: 20px 15px;
    box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.1);
    h1 {
        font-weight: 600;
        font-size: 33px;
        line-height: 25px; 
    }
    h3 {
        font-size: 23px;
        line-height: 30px;
        text-align: center;
    }
    p {
        font-size: 17px;
        color: var(--light-gray);
        line-height: 30px;
    }
    .img {
        display: flex;
        justify-content: center;
    }

    @media (max-width: 1023px) {
        h1 {
            text-align: center;
            font-size: 32px;
        }
        h3 {
            font-size: 20px;
            line-height: 30px;
        }
        p {
            text-align: left;
            line-height: 32px;
        }
    }
`;
export const About = styled.div`
    p {
        font-size: 18px;
        line-height: 40px;
        color: var(--light-gray);
        padding-bottom: 4rem;
        text-align: justify;
    }
    a {
        padding: 10px 40px;
        background: var(--light-green);
        text-decoration: none;
    }
    .cleaning-image {
        margin-top: -2.5rem;
    }
    @media (max-width: 1023px) {
       img {
            display: none;
       }
       .register-now {
            display: grid;
            justify-content: center;
            margin-top: -1.5rem;
       }
       p {
           text-align: left;
           margin-top: -15px;
           line-height: 30px;
           font-size: 17px;
       }
    }

`;
export const ServiceWrapper = styled.div`    
    h1 {
        font-size: 35px;
        line-height: 40px;
    }
    p {
        text-align: justify;
    }

    .about-text{
        font-size: 18px;
        line-height: 36px;
        color: var(--light-gray);
    }
    .laundry-service, .food-service, .cleaning-service {
       div {
            font-size: 16px;
            line-height: 28.5px;
            color: var(--light-gray);
            margin-top: -8px;
            height: 100px;
            width: 100%;
            padding: 0;
       } 
    }
    .food-service {
        margin-top: -3.5rem;
        margin-left: 18rem;
        position: relative;
        z-index: 2;
    }
    .laundry-service {
        margin-top: -5rem;
    }
    @media (max-width: 1020px) {
        margin-top: -120px;
        h1 {
            text-align: center;
        }
        .about-text {
            text-align: justify;
            font-size: 17px;
            line-height: 30px;
        }
        .service-cards {
            display: grid;
            gap: 2rem;
            margin-top: 3rem;
        }
        .food-service,.laundry-service,.cleaning-service {
            margin: 1.5rem auto 0 auto;
            p {
                text-align: left;
                line-height: 25px;
            }
        }
    }
    @media screen and (max-width: 1462px) and (min-width: 1021px) { 
        .food-service {
            margin-top: -3.5rem;
            margin-left: 15rem;
            z-index: 2;
        }
    }

`;
export const ServiceCard = styled.div`
    max-width: 270px;
    height: 200px;
    padding: 30px;
    background: #FFF;
    box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.1);

    img {
        margin-bottom: -10px;
        margin-left: 10px;
    }

    h3 {
        font-size: 22px;
        line-height: 25px;
    }

`;

export const DownloadSection = styled.div`
    display: grid;
    margin: 0 auto;
    text-align: center;

    h1 {
        font-size: 32px;
        line-height: 2.5rem;
    }
    .download-p {
        font-size: 18px;
        line-height: 2.5rem;
        color: #F7F7F7;
        padding-bottom: 30px;
    }

    .phone_one {
        position: relative;
        top: -2rem;
        left: 2rem;
        max-width: 600px;
        max-height: 600px;
    }

    @media (max-width: 1020px) {
        .phone_one {
            width: 300px;
            height: 300px;
            margin-left: -4rem;
        }
    }

`;
export const ButtonsWrapper = styled.div`
    margin:  0rem auto 0 auto;
    display: flex;
    gap: 2rem;    

    a {        
        padding: .2rem 2.5rem;
        text-decoration: none;
        display: flex;
        background: #fff;
        gap: .5rem;
        text-align: left;
        border-radius: 5px;
        color: var(--dark-gray);

        .icon {
            margin:  5px auto 0 auto;
            font-size: 25px;
        }
        .icon-apple{
            font-size: 30px;
            margin-top: 0px;
        }

        p {
            margin: 0;
            padding: 0;
            font-size: 13px;
        }
        .bold {
            font-weight: 600;
        }

    }
        
    @media (max-width: 1020px) {
        display: grid; 

        a{
            .icon {
                margin: 5px 0 0 0;
            }
        }
    }

`;



