import './AnimatedBubbles.css';

export const AnimatedBubbles = () => {
    return(
        <ul className="floating-bubbles from-top to-halfway">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    )
}

export const AnimatedBubblesHalf = () => {
    return(
        <ul className="floating-bubbles from-halfway to-bottom">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
        </ul>
    )
}