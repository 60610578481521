import { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { FaAngleDown, FaAngleUp, FaApple, FaGooglePlay } from "react-icons/fa";
import { useMediaQuery } from "react-responsive";

import { AnimatedBubbles, ComingSoonLite, DesktopViewLayout, Footer } from "../../components";
import { NavDataProps } from "./_types";
import Phones from "../../assets/images/download_section/iPhone-13.svg";
import { DownloadButton } from "../../components/DownloadButton";

import { 
    PricingSection, 
    ServiceSelectionContainer, 
    SingleRow } from "./PricingStyle";

import { PricingList } from "./PricingComponents";


const navbarData: NavDataProps[] = [
    {
         title:'Home', 
         path: '/', 
     },
     { 
         title:'Services', 
         path: '',
         iconClosed: <FaAngleUp /> , 
         iconOpen: <FaAngleDown />,
         dropdown: [
             { 
                 title: 'Cleaning', 
                 path: '/services/cleaning'
             },
             { 
                 title: 'Laundry', 
                 path: '/services/laundry'
             },
             { 
                 title: 'Food', 
                 path: '/services/food' 
             },
             { 
                 title: <>Market Runs <ComingSoonLite/></>, 
                 path: '#' //services/market-runs' 
             }
         ]
     },
     { 
         title:'Price Plan', 
         path: '/price-plan',
         active: true
     },
     { 
         title:'Contact', 
         path: '#',
         ref: "contactSection",
     }
 ]


export const Pricing = () => {    
    const [laundryChecked, setLaundryChecked] = useState(true);
    const [cleaningChecked, setCleaningChecked] = useState(false);
    const [foodChecked, setFoodChecked] = useState(false);   
    const [currentBrowser, setCurrentBrowser] = useState('');
    
    useEffect(() => {
            if(navigator.userAgent.includes("Chrome")) setCurrentBrowser('chrome');
    }, [])    
    
    const handleLaundryCheck = () =>  {
        if(!cleaningChecked && !foodChecked){
            return
        }else{            
            setLaundryChecked(!laundryChecked); 
        }   
    }
    const handleCleaningCheck = () => {
        if(!laundryChecked && !foodChecked){
            return
        }else{            
            setCleaningChecked(!cleaningChecked);
        }
    }
    const handleFoodCheck = () => {
        if(!laundryChecked && !cleaningChecked){
            return
        }else{            
            setFoodChecked(!foodChecked);
        }
    }

    const isMobile = useMediaQuery({
        query: "(max-width: 1020px)"
    });

    return (
        <DesktopViewLayout navData={navbarData} btnText={"Download"} width={"500px"}>
            { isMobile ? null : <AnimatedBubbles />}
            <PricingSection id="servicesSection">            
                <Container>
                    <Row className="pricing-row">
                        <Col className="pricing-col">
                            <h1>Budget Friendly Prices For Everyone</h1>
                            <p className="col-text">Choose the plan that best works for you, our team is ready to ease your stress</p>
                            <div>
                                <p className="interested-service-text">What service are you interested in ?</p>
                                <ServiceSelectionContainer>                               
                                    <div>
                                        <label htmlFor="laundry">Laundry</label>
                                        <input 
                                            id="laundry"
                                            type="checkbox" 
                                            checked={laundryChecked}
                                            onClick={handleLaundryCheck} />
                                    </div>
                                    <div>
                                        <label htmlFor="food" className="disabled">Food{/*&nbsp;&nbsp;<ComingSoon />*/}</label>
                                        <input 
                                            id="food"
                                            type="checkbox" 
                                            checked={foodChecked}                                    
                                            onClick={handleFoodCheck}
                                            />
                                    </div>
                                    <div>
                                        <label htmlFor="cleaning" className="disabled">Cleaning{/*&nbsp;&nbsp;<ComingSoon />*/}</label> 
                                        <input 
                                            id="cleaning"
                                            type="checkbox" 
                                            checked={cleaningChecked}
                                            onClick={handleCleaningCheck}
                                            // disabled
                                            /> 
                                    </div> 
                                </ServiceSelectionContainer>
                                <p className="market-runs-info">
                                    For <span className="emp-text">Market Runs</span>, Contact Support &darr;
                                </p>
                            </div>
                        </Col>
                        <Col className="pricing-col-two">
                            <PricingList 
                                laundry={laundryChecked} 
                                cleaning={cleaningChecked}
                                food={foodChecked} />
                        </Col>
                    </Row>
                </Container>
            </PricingSection>
            <PricingSection id="downloadSection" style={{ background: "#073B4C"}}>
                <SingleRow>
                    <div className='download-app-text'>
                        <h1>Download Our Mobile App </h1>
                        <p className="download-p">To enjoy all our services, download the Worka app on Google Playstore or App Store. </p> 
                    </div>
                    <div className="phone-div">
                        <img  src={Phones}  alt="phones-mockup" className="phone_one" />
                    </div>
                    <div className="download-btns">
                        <DownloadButton
                            path=""
                            background={"#fff"}
                            color={"#000000"}
                            icon={<FaApple />}
                            normText={"Download on"}
                            boldText={"App Store"} />
                        <DownloadButton
                            path=""
                            className="google-play-btn"
                            background={"#fff"}
                            color={"#000000"}
                            icon={<FaGooglePlay />}
                            normText={"Get it on"}
                            boldText={"Google Play"} />
                    </div>
                </SingleRow>
            </PricingSection>
            <div id="contactSection">
                <Footer />
            </div>
        </DesktopViewLayout>
    )
}