import { AnimatedBubbles, DesktopViewLayout, Footer } from "../../components";
import { NavDataProps } from "./_types";
import { laundrydata } from "../../utils";
import Section from "../../components/Section";
import { Container } from "./LaundryStyle";

const navbarData: NavDataProps[] = [
    { title:'Laundry', path: '/Laundry', active:true },
    { title:'Contact Us', path:'#', ref: 'footerSection'}
]


export const Laundry = () => {
    return (
        <DesktopViewLayout navData={navbarData} buttonPath="/pricing" btnText="Get Started" width={"230px"}>
            <Container>
                <AnimatedBubbles />
                <Section
                    buttonName="use-our-service-btn"
                    imageName="cleaning-tools-image"
                    colOne={laundrydata.sectionOne.colOne}
                    colTwo={laundrydata.sectionOne.colTwo}
                />
                <Section
                    background="rgba(255, 202, 102, 0.13)"
                    buttonName="download-now-btn"
                    imageName="galaxy-phone"
                    colOne={laundrydata.sectionTwo.colOne}
                    colTwo={laundrydata.sectionTwo.colTwo}
                />
                <div id="serviceSection">
                    <Section
                        header={laundrydata.sectionThree.headerText}
                        colOne={laundrydata.sectionThree.colOne}
                    />
                </div>
                <div id="downloadSection" >
                    <Section
                        background="rgba(17, 131, 171, 0.06)"              
                        colOne={laundrydata.sectionFour.colOne}
                        colTwo={laundrydata.sectionFour.colTwo}
                    />
                </div>
                <div id="footerSection">
                    <Footer />
                </div>
            </Container>  
        </DesktopViewLayout>
    )
}