import { TopNavbar } from "./_TopNavbar/TopNavBar";
import styled from 'styled-components';

const Wrapper = styled.div`
    min-width: 370px;
`

export const DesktopViewLayout = ({navData, btnText, width, buttonPath, children}: any) => {
    return (
        <Wrapper>
            <TopNavbar navData={navData} btnText={btnText} width={width} buttonPath={buttonPath} />
            {children}
        </Wrapper>
    )
}

