import styled from "styled-components"

const Wrapper = styled.p`
    margin: 3px 0 0 0;
    line-height: 18px;
    height: 18px;
    letter-spacing: 1px;
    padding: 0px 5px;
    font-size: 8px;
    background: #FFCA66;
    color: #fff;
    border-radius: 3px;
`;
const LiteWrapper = styled.span`
    font-size: 8px;
    padding: 3px;
    background: #FFCA66;
    color: #fff;
    border-radius: 3px;
`;

const TiltWrapper = styled.p`
    font-size: 8px !important;
    margin-top: -25px !important;
    margin-left: 50px !important;
    z-index: 4;
    position: absolute;
    padding: 3px 5px !important;
    background: #FFCA66;
    color: #fff;
    border-radius: 3px;
    transform: rotate(-45deg);
`;

export const ComingSoon = () => <Wrapper>coming soon</Wrapper>

export const ComingSoonLite = () => <LiteWrapper>coming soon</LiteWrapper>

export const ComingSoonTilt = () => <TiltWrapper>coming soon</TiltWrapper>