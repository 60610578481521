import Modal from 'react-modal';
import styled from 'styled-components';
import { useMediaQuery } from "react-responsive";

const Wrapper = styled.div`
`;
const style = {
    content: {
        minWidth: '6rem',
        width: '70%',
        maxWidth: '40rem',
        height: '250px',
        border: '1px solid #ccc',
        background: '#fff',
        margin: 'auto',
        borderRadius: '5px'
    },
    overlay: {    
        zIndex: '20',
        backgroundColor: 'rgba(0, 0, 0, 0.80)',
        transition: 'opacity 1000ms ease-in-out'
    }
}

const formStyle = {
    display:'flex', 
    justifyContent:'center', 
    marginTop:'2rem'
}
const formStyleMobile = {
    display:'grid', 
}
const inputStyle = {
    width:'50%', 
    padding: '10px 20px', 
    fontSize:'18px', 
    letterSpacing:'1px',
    border: '1px solid gray',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
    outline: 'none'
}
const inputStyleMobile = {    
    width:'85%',
    padding: '7px 20px', 
    fontSize:'18px', 
    letterSpacing:'1px',
    border: '1px solid gray', 
}
const btnStyle = {
    fontFamily:'PoppinsBold', 
    backgroundColor:'#073B4C', 
    color: '#fff', 
    paddingLeft:'25px', 
    paddingRight:'25px',
    letterSpacing:'1px',
    border: 'none',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px'
}
const btnStyleMobile = {
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '1rem',
    fontFamily:'PoppinsBold', 
    backgroundColor:'#073B4C', 
    color: '#fff',
    letterSpacing:'1px',
    border: 'none',
}

export const Popup = (props: any) => {
    const { closeModal, isModalOpen } = props;

    const isMobile = useMediaQuery({
        query: "(max-width: 900px)"
    });

    return(  
        <Wrapper>
            <Modal
                isOpen={isModalOpen} 
                ariaHideApp={false}
                style={style}>
                <div style={{display:'flex', justifyContent:'flex-end', fontSize:'20px', color:'#073B4C'}}>{closeModal}</div>
                <div className="modal-content">
                    <h3 style={{textAlign:'center', color:'#073B4C'}}>Subscribe to our Newsletter</h3>
                    <form 
                        action="https://worka.us14.list-manage.com/subscribe/post?u=bf27591fb1db3113337656bd9&amp;id=4b9d4946b7" 
                        method="post" style={isMobile ? formStyleMobile : formStyle}>                            
                        <input 
                            type="email" 
                            placeholder="user@example.com" 
                            style={isMobile ? inputStyleMobile : inputStyle}
                            name="EMAIL"
                            required />
                        <button style={isMobile ? btnStyleMobile :btnStyle} type="submit" name="subscribe">Subscribe</button>
                        </form>
                </div>
            </Modal>
        </Wrapper>         
    );
}
