import styled from 'styled-components';

export const Nav = styled.nav`
    position: fixed;
    top: 0;
    width: 100vw;
    background-image: linear-gradient(rgba(255,255,255,1) 65%, rgba(255, 255, 255, 0.9));
    padding: 10px 0;
    z-index: 5;
    
`;

export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;

    .logo {
        margin: auto 0 auto 50px;
    }
    a {
        text-decoration: none;
    }

    ul {
        display: flex;
        justify-content: space-between;
        padding: 0;

        .ref {
            margin-top: 3px;

            &:hover {
                border-bottom: 1px solid var(--dark-green);
                cursor: pointer;
            }
            &.active {
                color: var(--dark-green);
                font-weight: 600;
            }
        }
        a {
            color: var(--light-gray);

            .services-dropdown {
                width: 200px;
                display: flex;
                flex-direction: column;
                position: absolute;
                top: 4.5rem;
                
                background: #FFFFFF;
                border: 0.5px solid rgba(243, 243, 243, 0.491);
                box-sizing: border-box;
                box-shadow: 5px 10px 20px rgba(7, 59, 76, 0.05);
                border-radius: 5px;
                a {
                    padding: 5px 10px;
                    font-size: 15px;

                    &:hover {
                        background: #E7E9EB;
                    }
                }
            }
            .show {
                display: flex;
            }
            .hide {
                position: absolute;
                top: -9999999px;
            }
        }

        li {
            text-decoration: none;
            list-style: none;
            padding: 3px;
            font-size: 16px;
            display: flex;
            gap: .1rem;

            .collapse-icon {
                margin-top: 3px;
            }
            
            &:hover {
                border-bottom: 1px solid var(--dark-green);
                cursor: pointer;
            }
            &.active {
                color: var(--dark-green);
                font-weight: 600;
            }
        }
    }
    .btn {
        margin: auto 50px auto 0;

        a {
            padding: 7px 20px;
            border-radius: 5px;
            font-size: 17px;
            cursor: pointer;
        }
    }
`