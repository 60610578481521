import styled from "styled-components";

export const Navbar = styled.nav`
    position: fixed;
    top: 0;
    width: 100vw;
    min-width: 370px;
    background-image: linear-gradient(rgba(255,255,255,1) 65%, rgba(255, 255, 255, 0.9));
    padding: 20px 0;
    z-index: 5;

`;
export const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    padding-left: 20px;
`;

export const DropdownWrapper = styled.span`
    span {
        font-size: 20px;
        color: var(--dark-green);
        margin-right: 30px;
    }
`;

export const DropDownitems = styled.div`
    width: 100vw;
    min-width: 370px;
    background: rgba(255, 255, 255, 0.9);
    position: absolute;
    top: 70px;
    left: 0;

    ul { 
        padding-left: 0;
        display: grid;
        text-align: center;
        transition: 2s ease-in-out 1s;

        &.show-dropdown {
            display: block;
        }
        &.hide-dropdown {
            position: absolute;
            left: -9999999999px;
        }

        li { 
            list-style: none;  
            padding: 10px;
            cursor: pointer;
            a{        
                text-decoration: none;
                padding: 10px 30px;
                color: var(--dark-green);
                &:hover {
                    border-bottom: 2px solid var(--dark-green);
                }
                &:active {
                    border-bottom: 2px solid var(--dark-green);
                }          
            }
            }
        }
    }  
`;