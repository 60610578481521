import { Link } from "react-router-dom";
import styled from "styled-components";
import { ButtonWhite } from "./Button";
import { DownloadButton } from "./DownloadButton";
import { Link as ScrollLink } from "react-scroll";

const SectionWrapper = styled.section`    
    padding: 3rem 8rem 5rem 8rem;

    .header {
        font-family: PoppinsBold;
        font-weight: 600;
        font-size: 28.5px;
        line-height: 60px;
    }
    .cleaning-tools-image {
        margin-top: -1.5rem;
    }
    .galaxy-phone{
        margin-top: 80px;
    }
    .use-our-service-btn {
        background: var(--dark-green);
        color: var(--white);
        margin-top: 2rem;
        padding: 10px 30px;
        border-radius: 5px;
        position: relative;
        top: 5rem;
        cursor: pointer;
    }
    .download-now-btn{ 
        background: var(--light-green);
        color: var(--white);
        padding: 10px 30px;
        border-radius: 5px;
        position: relative;
        top: 10rem;
        left: 4rem;
        cursor: pointer;
    }

    .download-btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
`;

const Row = styled.div`
    display: flex;
    justify-content: space-between;
`;

const Col = styled.div`
    flex: 50%;
    h1 {
        font-weight: 700;
        font-size: 40px;
        line-height: 55px;
    }
    h2 {
        font-family: PoppinsBold;
        font-size: 32px;
        font-weight: 600;
        line-height: 45px;
        letter-spacing: 0em;
        text-align: left;
    }
    p {
        font-family: Poppins;
        font-size: 19px;
        line-height: 30px;
        letter-spacing: 0em;
        text-align: left;
        color: #6C6C6C;

    }
    ol {    
        max-width: 350px;
        padding-left: 0;   
        margin-top: -90px; 
        counter-reset: item; 
        list-style-type: none;

        li {
            display: block;
            height: 60px;
            padding-top: 40px;
            padding-bottom: 30px;
            margin-left: 4rem;
  
            &::before {
                content: counter(item, decimal-leading-zero);
                counter-increment: item;
                position: relative;
                top: 3.8rem;
                margin-left: -4rem;
                padding-right: 50px;
                font-family: PoppinsBold;
                font-weight: 600;
                font-size: 30px;
                line-height: 60px;
                color: rgba(7, 59, 76, 0.16);
            }

            h3 {
                font-weight: 600;
                font-size: 23px;
                line-height: 15px;
            }
            p {
                font-size: 19px;
                line-height: 30px;
                color: #6C6C6C;
            }
        }
    }

    @media (max-width: 900px) {
        flex: 100%;
    }
`;

const SingleRow = styled.div`
    display: flex;
    gap: 4rem;
    margin-top: 2.5rem;
    padding-bottom: 5rem;
`;

const Card = styled.div`
    max-width: 270px;
    padding: 20px;
    h4{
        font-family: PoppinsBold;
        font-weight: 600;
        font-size: 21px;
        line-height: 5px;
    }
    p{
        color: #6C6C6C;
        font-size: 17px;
        line-height: 30px;
    }
    a{
        text-decoration: none;
    }
`;

const Section = (props: any) => {
    const {colOne, colTwo, background, header, buttonName, imageName} = props;
    return(
        <SectionWrapper style={{background: background}}>
            { header ? <h2 className="header">{header}</h2> : null}
            <>
                { colOne && colTwo ?
                <Row>
                    <Col>
                        { !colOne.image ? 
                        <>
                            <h1>{colOne.headerText}</h1>
                            <p>{colOne.paragraphText}</p>
                            { !Array.isArray(colOne.btnText) ?
                                <ScrollLink
                                    to="serviceSection"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500} >
                                    <ButtonWhite text={colOne.btnText} className={buttonName} /> 
                                </ScrollLink>:
                               colOne.btnText.map((item: any, index: number) => (
                                    <DownloadButton
                                        key={index}
                                        background={item.background}
                                        color={item.color}
                                        path={item.path}
                                        icon={item.icon}
                                        normText={item.normText}
                                        boldText={item.boldText} />
                               ))
                            }  
                        </>
                        : <img src={colOne.image} className={imageName} alt={imageName} />
                        }
                    </Col>
                    <Col>
                        {!colTwo.image ? 
                        <>
                            <h2>{colTwo.headerText}</h2>
                            {Array.isArray(colTwo.paragraphText) ?
                                <ol>
                                    {colTwo.paragraphText.map((item: any, index: number) => (
                                        <li key={index}>
                                            <h3>{item.headerText}</h3>
                                            <p>{item.bodyText}</p>
                                        </li>
                                    ))}
                                </ol>
                            :<p>{colTwo.paragraphText}</p>
                            }
                            {
                            !Array.isArray(colTwo.btnText) ? 
                                <ScrollLink
                                    to={"downloadSection"}
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500} >
                                        <ButtonWhite text={colTwo.btnText} className={buttonName} /> 
                                </ScrollLink> :
                               <div className="download-btns">
                                   { colTwo.btnText.map((item: any, index: number) => (
                                        <DownloadButton
                                            key={index}
                                            background={item.background}
                                            color={item.color}
                                            path={item.path}
                                            icon={item.icon}
                                            normText={item.normText}
                                            boldText={item.boldText} />
                                    ))}
                               </div>
                            } 
                        </>
                            : <img src={colTwo.image} className={imageName} alt={imageName} />
                        }
                    </Col>
                </Row>
                : 
                <SingleRow>
                    {colOne.map((item: any, index: number) => (
                        <Card key={index} style={{background: item.background }}>
                            <img src={item.headerImage} alt={item.headerText} />
                            <h4>{item.headerText}</h4>
                            <p>{item.paragraphText}</p>
                            <Link to={item.link} style={{color: item.color}}>Explore Now</Link>
                        </Card>
                    ))}
                </SingleRow>
                }                
            </>
        </SectionWrapper>
    )
}

export default Section;