import { Link } from 'react-router-dom';
import { AiFillInstagram, AiFillLinkedin, AiFillTwitterCircle, AiOutlineWhatsApp } from 'react-icons/ai';

import {Wrapper, Col, Row, Logo, SocialLinks } from './FooterStyle';

import LogoWhite from '../../assets/images/worka_logo_white.svg';
import { FaFacebook } from 'react-icons/fa';

export const Footer = () => {
    return(
        <Wrapper>
            <Col>
                <Row>
                    <p>Contact</p>
                    <ul>
                        <li>help@worka.ng</li>
                        <li>+2347019403539</li>
                    </ul>
                </Row>
                <Row>
                    <p>Quick links</p>
                    <ul>
                        <li>
                            <a href="https://worka.tawk.help/" target="_blank" rel="noreferrer">Get Help</a>
                        </li>
                        <li>
                            <a href="https://worka.tawk.help/article/terms-and-conditions" target="_blank" rel="noreferrer">Terms of use</a>
                        </li>
                        <li>
                            <a href="https://worka.tawk.help/article/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a>
                        </li>
                        <li>
                            <a href="https://worka.tawk.help/article/frequently-asked-questions" target="_blank" rel="noreferrer">FAQ</a>
                        </li>
                    </ul>
                </Row>
                <Row>
                    <p>Download</p>
                    <ul>
                        <li>
                            <Link to="#">For Andriod</Link>
                        </li>
                        <li>
                            <Link to="#">For iOS</Link>
                        </li>
                    </ul>
                </Row>
                <Row>
                    <Logo src={LogoWhite} alt="logo" height="30px" width="150px" />
                    <div className='beneath_logo_text'>Enjoy Easy Life.</div>
                    <SocialLinks>
                        <a href="https://twitter.com/_worka" aria-label="Twitter" target="_blank" rel="noreferrer">
                            <AiFillTwitterCircle />
                        </a>
                        <a href="https://web.facebook.com/ng.worka" aria-label="Facebook" target="_blank" rel="noreferrer">
                            <FaFacebook />
                        </a>
                        <a href="https://www.instagram.com/worka_ng" aria-label="Instagram" target="_blank" rel="noreferrer">
                            <AiFillInstagram />
                        </a>
                        <a href="https://wa.me/+2347019403539" aria-label="Whatsapp" target="_blank" rel="noreferrer">
                            <AiOutlineWhatsApp />
                        </a>
                        {/* <a href="#" target="_blank" rel="noreferrer">
                            <AiFillLinkedin />
                        </a> */}  
                    </SocialLinks>
                </Row>
            </Col>
            <div className='copyright'>© 2022 Worka by Leom Technologies</div>
        </Wrapper>
    )
} 