import { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { useCookies } from 'react-cookie';
import { FaApple, FaAngleDown, FaAngleUp, FaGooglePlay, FaStar } from 'react-icons/fa';
import { AiFillCloseCircle, AiOutlineThunderbolt } from 'react-icons/ai';
import { Link as ScrollLink } from "react-scroll";
import CookieConsent from 'react-cookie-consent';

import CleaningIcon from "../../assets/images/service_section/cleaning.svg";
import FoodIcon from "../../assets/images/service_section/food.svg";
import LaundryIcon from "../../assets/images/service_section/laundry.svg";
import Phones from "../../assets/images/download_section/iPhone-13.svg";
import AboutImage from "../../assets/images/aboutUs_section/cleaning-laundry-image.svg";

import { DesktopViewLayout, Button, ImageCarousel, CarouselItem, AnimatedBubbles, Footer, AnimatedBubblesHalf, ComingSoonLite, Popup, ComingSoonTilt } from "../../components";
import { NavDataProps } from "./_types";
import { 
    Section, Col, Row, 
    ImageContainer, RatingWrapper, 
    Service, Card, About,
    ServiceCard, ServiceWrapper,
    DownloadSection, ButtonsWrapper,
} from "./HomeStyle";
import { Link } from "react-router-dom";
import { DownloadButton } from "../../components/DownloadButton";

const navbarData: NavDataProps[] = [
    {
         title:'Home', 
         path: '#', 
         ref: "introSection",
         active: true
     },
     { 
         title:'About', 
         path: '#',
         ref: "aboutSection",
     },
     { 
         title:'Services', 
         path: '',
         iconClosed: <FaAngleUp /> , 
         iconOpen: <FaAngleDown />,
         dropdown: [
            { 
                title: <>Laundry</>, 
                path: '/services/laundry'
            },
            { 
                 title:  <>Cleaning {/*<ComingSoonLite/>*/}</>, 
                 path: 'services/cleaning'
             },
             { 
                 title: <>Food {/*<ComingSoonLite/>*/}</>, 
                 path: 'services/food' 
             },
             { 
                 title: <>Market Runs <ComingSoonLite /></>, 
                 path: '#' //services/market-runs' 
             }
         ]
     },
     { 
         title:'Price Plan', 
         path: 'pricing'
     },
     { 
         title:'Contact', 
         path: '#',
         ref: "contactSection",
     }
 ]


export const Home = () => {

    const [showModal, setShowModal] = useState(false); 
    const [cookie, setCookie ] = useCookies(['display_popup']);

    let date =  new Date(Date.now() + 30 * 24 * 60 * 60 * 1000);

    useEffect(() => {
        if(cookie['display_popup'] !== 'true'){ 
            setTimeout(()=> setShowModal(true), 15000);
            setCookie("display_popup", 'true', { path: '/', expires: date } );
        }else{
            setShowModal(false);
            console.log(date);
        }
    }, []);

    
    const isMobile = useMediaQuery({
        query: "(max-width: 1020px)"
    });


    return(
        <>
            <DesktopViewLayout navData={navbarData} btnText={"Download"} width={"500px"}>
                <Section id="introSection" style={{marginTop: "107px"}}>
                    {isMobile ? null :<AnimatedBubbles />}
                    <Col>
                        <Row style={{width: "49.8%"}}>
                            <h1 className="intro_text"><span>Enjoy Easier Life,</span><br/>Do More With Your Time</h1>
                            <p className="intro_subtext">
                                Worka is a tech-based general service provider that ensures you enjoy an easy life, 
                                with our custom service plan. Worka offers fresh meal (prepared and delivered to you), 
                                Market runs (We go shopping on your behalf), Laundry services, and Cleaning services.
                            </p>
                            <div className="btns">
                                <ScrollLink
                                    to="servicesSection"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500} 
                                    className="worka-it">                
                                    <Button  text="Worka it !"/>
                                </ScrollLink>
                                <ScrollLink
                                    to="contactSection"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500} className="intro-contact-us">Contact Us</ScrollLink>
                            </div>
                        </Row>
                        <Row style={{width: "49.8%"}}>
                            <ImageCarousel>
                                <CarouselItem>
                                    <ImageContainer src={require('../../assets/images/slide/slide1.png')}  alt="laundry-service" />
                                </CarouselItem>
                                <CarouselItem>
                                    <ImageContainer src={require('../../assets/images/slide/slide2.png')}  alt="cleaning-service" />
                                </CarouselItem>
                                <CarouselItem>
                                    <ImageContainer src={require("../../assets/images/slide/slide4.png")}  alt="food" />
                                </CarouselItem>
                            </ImageCarousel>
                        </Row>
                    </Col>
                </Section>
                <Section style={{backgroundColor: "#073B4C", color: "white" }}>
                    <Col className="trusted_text-col">
                        <Row style={{width: "40.1%"}}>
                            <p className="trusted_text">Worka is Currently available in the following Locations</p>
                        </Row>
                        <Row style={{width: "40.1%"}}>
                            <p className="trusted_text">Kubwa, Dutse-Alhaji, Dawaki, Gwarinpa and Katampe</p>
                            {/* <RatingWrapper>
                                <h1>4.7</h1>
                                <div className="star-icon">
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                    <FaStar />
                                </div>
                            </RatingWrapper>                             */}
                        </Row>
                        {/* <Row style={{width: "33.1%", paddingTop: "15px", paddingBottom: '15px'}}>
                            <Service>
                                <span><AiOutlineThunderbolt /></span> <span className="service">SERVICE</span>
                            </Service>
                        </Row> */}
                    </Col>
                </Section>                
                <Section>
                    <div className="how-it-works-container">
                        { isMobile ? null :<AnimatedBubblesHalf /> }
                        <Col className="how_it_works-col">
                            <Row style={{ width: "28%", marginTop: "-30px" }}>
                                <Card className="card-item-main">
                                    <h1>How It Works</h1>
                                    <p>From taking off the strain of doing dirty laundry, giving your living space a complete cleaning makeover, to serving you with freshly cooked, mouthwatering dishes, we have you covered on all front. 
                                        Want to embrace the good life with Worka?<br/>
                                        Here’s how to get started {isMobile ? null : <>&rarr;</>} 
                                    </p>
                                </Card>
                            </Row>
                            <Row style={{ width: "21.9%" }} className="card-register">
                                <Card className="card-item">
                                    <div className="img">
                                        <img src={require("../../assets/images/reg-acct.png")} alt={"register account"} width="45px" height="41px" />
                                    </div>
                                    <h3>Register An Account</h3>
                                    <div className="card-p"><p>Create a personal account to get access to our top services. </p></div>
                                </Card>
                            </Row>
                            <Row style={{ width: "21.8%" }} className="card-check-service"> 
                                <Card className="card-item">
                                    <div className="img">
                                        <img src={require("../../assets/images/checklist.png")} alt={"check services"} width="40px" height="42px" />
                                    </div>
                                    <h3>Check Services</h3>
                                    <div className="card-p"><p>Check out the services we offer, and order for a Worka for your domestic needs.</p></div>
                                </Card>
                            </Row>
                            <Row style={{ width: "21.8%" }} className="card-sbe">
                                <Card className="card-item">
                                    <div className="img">
                                        <img src={require("../../assets/images/sit-enjoy.png")} alt={"Sit and Enjoy"} width="41px" height="33px" />
                                    </div>
                                    <h3>Sit Back And Enjoy</h3>
                                    <div className="card-p"><p>Your Worka is on the way, sit back and enjoy, let's get our hands dirty!</p></div>
                                </Card>
                            </Row>
                        </Col>
                    </div>
                </Section>
                <Section id="aboutSection" style={{marginTop:"70px"}}>
                    <h1 className="about-us">About Us</h1>
                    <Col style={{marginTop: "3rem" }}>
                        <Row style={{width:"45%", marginTop: 0}}>
                            <About>
                                <p>
                                We are a resource center for all your domestic and housekeeping needs; from dirty laundry, house cleaning, and even getting freshly cooked meals delivered to your doorstep, we are your one-stop guy in outsourcing your housekeeping needs.<br /> 
                                At Worka, we usher you into the good life by taking the stress of domestic chores off your hands, so you have more time to pursue your ambitions without that mean stack of dirty dishes weighing you down!
                                </p>
                                <a href="/pricing" className="register-now">
                                    <Button text="Register Now" className="" />
                                </a>
                            </About>
                        </Row>
                        <Row style={{width:"53%"}}>
                            <About>
                                <img src={AboutImage} height="650px" width="600px" alt="cleaning" className="cleaning-image"/>
                            </About>
                        </Row>
                    </Col>
                </Section>
                <Section  id="servicesSection">
                    <ServiceWrapper>
                        <Col>
                            <Row style={{ width: "43%", marginTop: 0}} className="services-section">
                                <h1>Services</h1>
                                <p className="about-text">For every damsel in distress, there is a fairy godmother who comes through just in time to fix her up for the spotlight. <br/>
                                    At Worka, we are your personal housekeeping fairies, with the sole objective of ensuring that you stress less over domestic chores, and have more time doing what you actually love.<br/>
                                    We have a Worka for you for the following domestic needs {isMobile ? null : <>&rarr;</>} 
                                </p>
                            </Row>
                            <Row style={{ width: "53%"}}>
                                <div className="service-cards">
                                    <ServiceCard className="cleaning-service">
                                        <img src={CleaningIcon} alt="Cleaning" width="27px" height="40px" />
                                        <h3>Cleaning</h3>
                                        <div>                                        
                                            <p>Late for an appointment and your living space is a mess?  Don’t fret, we got you!<br/></p>
                                        </div>
                                    </ServiceCard>
                                    <ServiceCard className="food-service">
                                        <img src={FoodIcon} alt="Food" width="41px" height="39px" />
                                        <h3>Food</h3>
                                        <div>
                                            <p>Now to the fun part, <br/>
                                                Freshly cooked meals delivered to your doorstep? We got you!
                                            </p>
                                        </div>
                                        </ServiceCard>
                                    <ServiceCard className="laundry-service">
                                        <img src={LaundryIcon} alt="Laundry" width="40px" height="45px" />
                                        <h3>Laundry</h3>
                                        <div className="laundry-div">
                                            <p>Now your weekend can get a break from doing back-breaking laundry, let's handle it.</p>
                                        </div>
                                    </ServiceCard>
                                </div>
                            </Row>
                        </Col>
                    </ServiceWrapper>                    
                </Section>
                <Section id="downloadSection"  style={{backgroundColor: "#073B4C", marginTop: "150px", color: "white", padding: "30px 0 40px 0"}}>
                    <Col className="download-section">
                        <DownloadSection>
                            <h1>Download Our Mobile App </h1>
                            <p className="download-p">To enjoy all our services, download the Worka app on Google Playstore or App Store. </p>
                            <div>
                                <img  src={Phones}  alt="phone 1" className="phone_one" />
                            </div>
                            <ButtonsWrapper>
                               <a href='#'>
                                    <div className="icon icon-apple">
                                        <FaApple />
                                    </div>
                                    <div className="download-text">
                                        <p>Download on</p>
                                        <p className="bold">App Store</p>    
                                        <ComingSoonTilt />
                                    </div>             
                                </a>               
                                <a href='#'>
                                    <div className="icon icon-google-play">
                                        <FaGooglePlay />
                                    </div>
                                    <div className="download-text google-play-text">
                                        <p>Get it on</p>
                                        <p className="bold">Google Play</p>    
                                        <ComingSoonTilt />
                                    </div>             
                                </a>
                            </ButtonsWrapper>
                        </DownloadSection>
                    </Col>
                </Section>
                <div id="contactSection">
                    <Footer />
                </div>
               { showModal ?  
                    <Popup
                        closeModal={<div onClick={() => setShowModal(false)}><AiFillCloseCircle /></div>}
                        isModalOpen={showModal}
                     />    
                : null }  
                <CookieConsent
                    location="bottom"
                    buttonText="I understand"
                    cookieName="cookie-consent"
                    style={{ background: "rgba(0, 0, 0, 0.8)" }}
                    buttonStyle={{ color: "#4e503b", fontSize: "13px", borderRadius: "5px", fontFamily: 'poppins' }}
                    expires={50}
                    >
                    This website uses cookies to enhance the user experience.
                </CookieConsent>    
            </DesktopViewLayout>   
        </>
    )
}


