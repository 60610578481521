import styled from "styled-components";

const mobile = '658px';

export const PricingSection = styled.section`
    margin-top: 2rem;
    padding: 30px 10px;

    .pricing-row{
        min-width: 350px;
    }

    .pricing-col {
        h1 {
            color: var(--dark-green);
            font-size: 30px;
        }
        .col-text, .interested-service-text, market-runs-info {
            font-size: 17px;
        }
        .market-runs-info{
            .emp-text {
                font-weight: 600;
            }
        }
    } 
    .pricing-col-two{
        margin-top: 2rem;
        min-width: 350px;
    }

    @media screen and (min-width: 1020px){ 
        padding: 5rem 0rem 5rem 0rem;
        .pricing-row {
            display: flex;
            justify-content: space-around;
        }
        .pricing-col {
            width: 42%;

            h1 {
                font-size: 35px;
                line-height: 40px;
            }
            .col-text, .interested-service-text, market-runs-info {
                font-size: 17px;
            }
            .market-runs-info{
                .emp-text {
                    font-weight: 600;
                }
            }
        }
        .pricing-col-two {
            width: 39%;
        }
    }

    
`;

export const ServiceSelectionContainer = styled.div`
    width: 82%;
    padding: 30px 50px;
    background: rgba(6, 207, 153, 0.31);
    border-radius: 12px;

    div{
        display: flex;
        justify-content: space-between;
        padding-top: 20px;
        padding-bottom: 20px;

        label {
            color: var(--dark-green);
            font-weight: 600;
            display: flex;
        }
        input{
            &:checked {
                background: var(--dark-green);
            }
        }
    }
    @media screen and (max-width: 1020px){
        width: 89%;
        padding-left: 20px;
        padding-right: 20px;
    }
`;

export const SingleRow = styled.div`
    display: grid;
    margin: 0 auto;
    text-align: center;

    .download-app-text {
        width: 80%;
        margin: 0 auto;
    }

    h1 {
        font-size: 32px;
        line-height: 1rem;
        color: #fff;
    }
    .download-p {
        font-size: 19px;
        line-height: 2rem;
        color: #F7F7F7;
    }

    .phone_one {
        position: relative;
        top: 0;
        left: 2rem;
        max-width: 600px;
        max-height: 600px;
    }
    .download-btns {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        margin: -10rem auto 0 auto;
    }

    @media (max-width: 1020px) {
        .phone_one {
            width: 300px;
            height: 300px;
            margin-left: -4rem;
        }
        h1, .download-p {
            line-height: 2rem;
        }
        .download-btns {
            display: grid;
            gap: 1.5rem;
            margin-top: 2rem;
            a { 
                margin-top: 0;
            }
            }
        }
    }
`;



// Pricing Components
export const PricingListWrapper = styled.div`
    transition: all 5s ease;
    .proceed-to-payment_text{
        float: right;
        padding: 5px 10px;
        margin-top: 20px;
        text-align: right;
        background:  rgba(6,207,153,0.31);
        border-radius: 8px;
        color: var(--dark-green);
        font-weight: 600;
        cursor: pointer;

        &:hover {
            box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.4);
        }
    }
`;

export const LaundryPlanWrapper = styled.div`
    max-width: 100%;
    margin-right: 0;
    padding: 15px 20px;
    border-radius: 12px;
    border: 2px solid rgba(6,207,153,0.31);
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #073B4C;

    .type, .frequency, .increase-decrease-section, .extras-section {
        display: grid;
    }
    label {
        font-size: 14px;
        padding-bottom: 5px;
    }
    
    .type-frequency-selectors{
        display: flex;
        justify-content: space-between;

        select {
            font-family: Poppins;
            padding: 12px 10px;
            border-radius: 5px;
            border: .5px solid rgb(239, 239, 239);
            color: var(--dark-green);  
            &:focus{
               outline: none;
            }
        }
    }
    .increase-decrease-section{
        margin-top: 20px;
        div{
            display: flex;  
        }

        input {            
            font-family: Poppins;
            padding: 12.5px 10px;
            flex: 100;
            text-align: center;
            border-right: none;
            border-left: none;
            border-top: .5px solid rgb(239, 239, 239);
            border-bottom: .5px solid rgb(239, 239, 239);

            &:focus{
                outline: none;  
            }
        }
        button {
            padding: 10px 15px;
            border-radius: 0;
            border: none;
            vertical-align: middle;
        }
        .decrease {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        .increase {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
    }
    .extras-section {
        width: 100%;
        margin-top: 2rem;

        .extras-item-list-container {

            .extras-item-list {
                display: flex;
                justify-content: space-between;
                padding: 0 15px;
                border-radius: 5px;
                border: .5px solid rgb(239, 239, 239);

                p{
                    max-width: 430px;
                    white-space: nowrap; 
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 30px;
                    line-height: 30px;
                    cursor: pointer;
                }
                .caret-icon {
                    margin-top: 1.3rem;
                }
            }
            
            .show{
                display: block;
            }
            .hide {
                display: none;
            }
            
            ul{     
                margin-top: -5px;
                padding: 5px 20px;
                font-size: 14px;
                border: .5px solid rgba(243, 243, 243, 0.491);
                border-top: none;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                box-shadow: 0px 15px 20px rgba(243, 243, 243, 0.491);
            }

           
        }
      
    }

    .amount-section{
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        font-size: 17px;
        font-weight: 600;
    }

    @media screen and (max-width: ${mobile}){
        .extras-section {
            .extras-item-list-container  {
                width: 100%;
                min-width: 315px;
            }
        }
    }
`;

export const ExtraItemsContainer = styled.div`
    li {
        display: flex;
        justify-content: space-between;

        .extras-count {
            display: flex;
        }
        label {
            line-height: 52px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        button {
            height: 30px;
            width: 30px;
            margin: auto;
            border-radius: 5px;
            padding: 0;
            border: 0;
            
            img{
                height: 15px;
                width: 15px;
            }
        }
    }

`;


export const FoodPlanWrapper = styled.div`
    max-width: 100%;
    padding: 15px 20px;
    border-radius: 12px;
    border: 2px solid rgba(6,207,153,0.31);
    font-weight: 400;
    font-size: 15px;
    color: #073B4C;

    .frequency-and-number-of-meals{
        width: 100%;
        display: flex;
        gap: 6.7rem;

        label {
            font-size: 14px;
            padding-bottom: 5px;
        }

        select {
            font-family: Poppins;
            padding: 12px 20px;
            border-radius: 5px;
            border: .5px solid rgb(239, 239, 239);
            color: var(--dark-green);
            // -webkit-appearance: none;
            // -moz-appearance: none;
            // appearance: none;  
            &:focus{
               outline: none;
            }
        }
        input {            
            font-family: Poppins;
            padding: 12.5px 10px;
            max-width: 37%;
            text-align: center;
            border-right: none;
            border-left: none;
            border-top: .5px solid rgb(239, 239, 239);
            border-bottom: .5px solid rgb(239, 239, 239);

            &:focus{
                outline: none;  
            }
        }
        button {
            padding: 10px 15px;
            border-radius: 0;
            border: none;
            vertical-align: middle;
        }
        .decrease {
            border-top-left-radius: 5px;
            border-bottom-left-radius: 5px;
        }
        .increase {
            border-top-right-radius: 5px;
            border-bottom-right-radius: 5px;
        }
        
    }
    
    .frequency, .number-of-meals{
        display: grid;
    }

    .delivery-days {
        display: grid;
        margin-top: 20px;

        .delivery-days-label {
            font-size: 14px;
            padding-bottom: 5px;
        }
    }

    .amount-section{
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        font-size: 17px;
        font-weight: 600;
    }

    @media screen and (max-width: ${mobile}){
        .frequency-and-number-of-meals{
            gap: 1rem;

            select {
                padding: 5px 3px;
            }

            input {
                width: 25%;
            }
        }
    }

   
`;

export const DailyDeliveryWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;


    div {
        padding: 13px 25px;
        border-radius: 7px;
        border: 1px solid rgb(239, 239, 239);        
    }
    input {
        display: none;     
    }
    label {        
        cursor: pointer;  
    }
    .selected {
        color: var(--light-green);
        border: 1px solid var(--light-green);
    }

    @media screen and (max-width: ${mobile}){
        div {
            padding-left: 13px; 
            padding-right: 13px;      
        }
    }
`;

export const WeeklyDeliveryWrapper = styled.div`    
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    div {
        padding: 14px;
        border-radius: 7px;
        border: 1px solid rgb(239, 239, 239);        
    }
    input {
        display: none;     
    }
    label {        
        cursor: pointer;  
    }    
    .selected {
        color: var(--light-green);
        border: 1px solid var(--light-green);
    }

    @media screen and (max-width:${mobile}){
        div {
            padding-left: 10px; 
            padding-right: 10px;      
        }
    }
`;

export const TwicePerWeekDelivery = styled.div`        
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10px;

    div {
        padding: 14px;
        border-radius: 7px;
        border: 1px solid rgb(239, 239, 239);        
    }
    input {
        display: none;     
    }
    label {        
        cursor: pointer;  
    }    
    .selected {
        color: var(--light-green);
        border: 1px solid var(--light-green);
    }

    @media screen and (max-width: ${mobile}){
        div {
            padding-left: 10px; 
            padding-right: 10px;      
        }
    }
`;


export const CleaningPlanWrapper = styled.div`
    max-width: 100%;
    margin-right: 0;
    padding: 15px 20px;
    border-radius: 12px;
    border: 2px solid rgba(6,207,153,0.31);
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    color: #073B4C;

    .type, .frequency, .extras-section {
        display: grid;
    }
    label {
        font-size: 14px;
        padding-bottom: 5px;
        width: 60%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .type-frequency-selectors{
        display: flex;
        justify-content: space-between;

        select {
            font-family: Poppins;
            padding: 12px 10px;
            border-radius: 5px;
            border: .5px solid rgb(239, 239, 239);
            color: var(--dark-green); 
            &:focus{
                outline: none;
            }
        }
    }
    .increase-decrease-section{
        margin-top: 20px;
    }
    
    .extras-section {
        width: 100%;
        margin-top: 2rem;

        .extras-item-list-container {

            .extras-item-list {
            display: flex;
            justify-content: space-between;
            padding: 0 15px;
            border-radius: 5px;
            border: .5px solid rgb(239, 239, 239);

                p{
                    max-width: 430px;
                    white-space: nowrap; 
                    overflow: hidden;
                    text-overflow: ellipsis;
                    height: 30px;
                    line-height: 30px;
                }
            
                .caret-icon {
                    margin-top: 1.3rem;
                }
            }
        
            .show{
                display: block;
            }
            .hide {
                display: none;
            }
            
            ul{     
                margin-top: -5px;
                padding: 5px 20px;
                font-size: 14px;
                border: .5px solid rgba(243, 243, 243, 0.491);
                border-top: none;
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
                box-shadow: 0px 15px 20px rgba(243, 243, 243, 0.491);
            } 
        }       
      
    }
    .amount-section{
        margin-top: 2rem;
        display: flex;
        justify-content: space-between;
        font-size: 17px;
        font-weight: 600;
    }

    @media screen and (max-width: ${mobile}){
        .extras-section {
            .extras-item-list-container  {
                width: 100%;
                min-width: 315px;
            }
        }
    }

`;


export const TotalAmountWrapper = styled.div`
    display: flex;
    justify-content: space-between;    
    padding: 25px 20px;
    border-radius: 12px;
    border: 2px solid rgba(6,207,153,0.31);
    font-size: 17px;
    font-weight: 600;
    color: var(--dark-green);
`;



const mainStyle = {
    content: {
        minWidth: '6rem',
        width: '70%',
        maxWidth: '30rem',
        border: '1px solid #ccc',
        background: '#fff',
        margin: 'auto',
        borderRadius: '5px'
    },
    overlay: {    
        zIndex: '20',
        backgroundColor: 'rgba(0, 0, 0, 0.80)',
        transition: 'opacity 1000ms ease-in-out'
    }
}

const formStyle = {
    display:'grid', 
    gap: '2rem',
    justifyContent:'center', 
    marginTop:'2rem'
}
const formStyleMobile = {
    display:'grid',  
    gap: '2rem',
}
const inputStyle = { 
    padding: '10px 20px', 
    fontSize:'16px', 
    letterSpacing:'1px',
    border: '1px solid gray',
    borderRadius: '5px',
    outline: 'none'
}
const inputStyleMobile = {    
    width:'85%',
    padding: '7px 20px', 
    fontSize:'18px', 
    letterSpacing:'1px',
    border: '1px solid gray', 
}
const btnStyle = {
    fontFamily:'PoppinsBold', 
    backgroundColor:'#073B4C', 
    color: '#fff', 
    padding: '10px 25px',
    letterSpacing:'1px',
    border: 'none',
    borderRadius: '5px'
}
const btnStyleMobile = {
    width: '100%',
    paddingTop: '10px',
    paddingBottom: '10px',
    marginTop: '1rem',
    fontFamily:'PoppinsBold', 
    backgroundColor:'#073B4C', 
    color: '#fff',
    letterSpacing:'1px',
    border: 'none',
}

const errorText = {
    color: 'red'
}

export const ModalStyle = {
    mainStyle,
    formStyle,
    formStyleMobile,
    inputStyle,
    inputStyleMobile,
    btnStyle,
    btnStyleMobile,
    errorText
}
